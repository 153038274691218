import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { usePOS } from '../../contexts/POSContext';
import styled from 'styled-components';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { fetchTablesForArea } from '../../services/posApiService';
import CircularProgress from '@mui/material/CircularProgress';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Container = styled.div`
  padding: 10px;
  padding-top: 20px;
  background-color: #121212; /* Dark mode background */
  height: 80vh; // Make it swipeable full screen (to be adjusted with 'calc')
`;

const getBorderColor = (status) => {
  switch (status) {
    case 'in_progress':
      return 'yellow';
    case 'completed':
      return 'green';
    default:
      return '#CBD5E1';
  }
};

const TableContainer = styled.div.attrs((props) => ({
  style: {
    border: `1px solid ${getBorderColor(props.$status)}`,
    borderRadius: '8px'
  }
}))`
  width: 100%;
  height: 100%;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const TableNumber = styled.h4`
  align-self: flex-start;
  padding-left: 10px;
  text-align: left;
  margin: 0;
  color: #CBD5E1;
`;

const TableSeats = styled.p`
  align-self: flex-start;
  padding-left: 10px;
  text-align: left;
  font-size: 15px;
  margin: 0;
  color: #CBD5E1;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const POSFloorMap = ({ areaId }) => {
  const { token } = useAuth();
  const { setSelectedTable } = usePOS();
  const [tables, setTables] = useState([]);
  const [layout, setLayout] = useState([]);
  const [originalLayout, setOriginalLayout] = useState([]);
  const [sortedLayout, setSortedLayout] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatLayout = useCallback((tables) => {
    return tables.map((table) => ({
      i: table.id.toString(),
      x: table.position_x,
      y: table.position_y,
      w: 1,
      h: 1,
      static: true,
      status: table.status,
    }));
  }, []);

  const loadTables = useCallback(async () => {
    setLoading(true);
    try {
      const fetchedTables = await fetchTablesForArea(areaId, token);
      setTables(fetchedTables);
      const newLayout = formatLayout(fetchedTables);
      setLayout(newLayout);
      setOriginalLayout(newLayout);

      // Create sorted layout by table number for smaller screens
      const sortedTables = [...fetchedTables].sort((a, b) => a.number - b.number);
      const newSortedLayout = sortedTables.map((table, index) => ({
        i: table.id.toString(),
        x: 0,
        y: index,
        w: 1,
        h: 1,
        static: true,
        status: table.status,
      }));
      setSortedLayout(newSortedLayout);
    } catch (err) {
      console.error('Error fetching tables:', err);
    } finally {
      setLoading(false);
    }
  }, [areaId, token, formatLayout]);

  useEffect(() => {
    loadTables(); // Initial load
  }, [areaId, loadTables]);

  const handleTableSelect = (tableId) => {
    setSelectedTable(tableId);
  };

  const handleBreakpointChange = (newBreakpoint) => {
    if (newBreakpoint === 'xs' || newBreakpoint === 'xxs') {
      setLayout(sortedLayout);
    } else {
      setLayout(originalLayout);
    }
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: originalLayout, md: originalLayout, sm: originalLayout, xs: sortedLayout, xxs: sortedLayout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 8, md: 8, sm: 8, xs: 1, xxs: 1 }}
        rowHeight={100}
        isDraggable={false}
        isResizable={false}
        onBreakpointChange={handleBreakpointChange}
        margin={[20, 20]}
        containerPadding={[20, 20]}
      >
        {tables.map((table) => (
          <div
            key={table.id}
            data-grid={layout.find(l => l.i === table.id.toString())}
            onClick={() => handleTableSelect(table.id)}
          >
            <TableContainer $status={table.status}>
              <TableNumber>#{table.number}</TableNumber>
              <TableSeats>{table.number_of_seats} seats</TableSeats>
            </TableContainer>
          </div>
        ))}
      </ResponsiveGridLayout>
    </Container>
  );
};

export default POSFloorMap;
