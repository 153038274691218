import api from './apiInstance'; // Import the Axios instance with interceptors

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to fetch menus based on restaurant ID
export const fetchMenusByRestaurant = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/menus/?restaurant_id=${restaurantId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menus:', error);
    throw error;
  }
};

// Function to fetch menu items for a specific menu
export const fetchMenuItemsByMenu = async (menuId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/menu-items/?menu_id=${menuId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items:', error);
    throw error;
  }
};

// Function to fetch combos for a specific menu
export const fetchCombosByMenu = async (menuId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/combos/?menu_id=${menuId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching combos:', error);
    throw error;
  }
};

// Function to fetch restaurant details for end-users
export const fetchUserRestaurantDetails = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/restaurants/${restaurantId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching restaurant details for end users:', error);
    throw error;
  }
};

// Function to fetch live menu details for preview or customer view
export const fetchLiveMenuDetails = async (menuId, restaurantId = null, tableId = null) => {
  let url = `${API_BASE_URL}users/live-menu/${menuId}/`;

  // Adjust the URL depending on the presence of restaurantId and tableId
  if (restaurantId && tableId) {
    url = `${API_BASE_URL}users/live-menu/restaurant/${restaurantId}/table/${tableId}/${menuId}/`;
  } else if (restaurantId) {
    // If only restaurantId is provided
    url = `${API_BASE_URL}users/live-menu/restaurant/${restaurantId}/${menuId}/`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching live menu details:', error);
    throw error;
  }
};

// Function to confirm and place and order
export const placeOrder = async (tableId, orderData) => {
  try {
    const response = await api.post(`${API_BASE_URL}users/place-order/${tableId}/`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error placing order:', error);
    throw error;
  }
};

// Function to fetch latest bill details
export const fetchBillDetails = async (orderId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/request-bill/${orderId}/`, {
      params: {
        session_key: localStorage.getItem('session_key'), // Assuming you store session_key in localStorage
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching bill details:', error);
    throw error;
  }
};

// Function to fetch payment options
export const fetchPaymentOptions = async (orderId) => {
  try {
    const response = await api.get(`${API_BASE_URL}users/payment-options/${orderId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching payment options:', error);
    throw error;
  }
};

// Function to initiate payment
export const initiatePayment = async (orderId, paymentData) => {
  try {
    const response = await api.post(`${API_BASE_URL}users/initiate-payment/${orderId}/`, paymentData);
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};


