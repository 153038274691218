// v. 2.00
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import { fetchPaymentOptions, initiatePayment } from '../../services/usersApiService';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const BillReviewModal = ({ isOpen, onClose, billDetails, orderId }) => {
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // For MUI Menu

  // Fetch payment options when the modal opens
  useEffect(() => {
    if (isOpen && orderId) {
      fetchPaymentOptions(orderId)
        .then((data) => {
          console.log('Fetched payment options:', data.options);
          setPaymentOptions(data.options);
        })
        .catch((error) =>
          console.error('Failed to fetch payment options:', error)
        );
    }
  }, [isOpen, orderId]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePaymentOptionSelect = async (option) => {
    const sessionKey = localStorage.getItem('session_key');

    let paymentData = {
      payment_option: option.replace(/ /g, '_').toLowerCase(),
      payment_method: 'digital',
    };

    switch (option) {
      case 'pay_full_bill':
        break;
      case 'pay_by_order':
        paymentData = { ...paymentData, session_key: sessionKey };
        break;
      case 'split_in_equal_parts':
        const numberOfSplits = prompt(
          'Enter the number of parts to split the bill into:',
          '2'
        );
        paymentData = {
          ...paymentData,
          number_of_splits: parseInt(numberOfSplits, 10),
        };
        break;
      default:
        console.error('Unhandled payment option:', option);
        return;
    }

    try {
      const response = await initiatePayment(orderId, paymentData);
      console.log('Payment response:', response);
    } catch (error) {
      console.error('Error initiating payment:', error);
    }

    handleMenuClose();
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (!isOpen || !billDetails) return null;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Your Bill</DialogTitle>
      <DialogContent dividers>
        <Typography>
          <strong>Restaurant:</strong> {billDetails.restaurant_name}
        </Typography>
        <Typography>
          <strong>Address:</strong> {billDetails.restaurant_address}
        </Typography>
        <Typography>
          <strong>Table Number:</strong> {billDetails.table_number}
        </Typography>
        <Typography>
          <strong>Date:</strong> {formatDate(billDetails.issue_date)}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Your Items</Typography>
        {billDetails?.session_items?.map((item, index) => (
          <Box
            key={`session-item-${index}`}
            display="flex"
            justifyContent="space-between"
            fontWeight={item.is_current_user_item ? 'bold' : 'normal'}
          >
            <Typography>{item.name}</Typography>
            <Typography>
              {item.quantity} x €{item.price.toFixed(2)}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Other Items</Typography>
        {billDetails?.all_items
          ?.filter((item) => !item.is_current_user_item)
          .map((item, index) => (
            <Box
              key={`other-item-${index}`}
              display="flex"
              justifyContent="space-between"
            >
              <Typography>{item.name}</Typography>
              <Typography>
                {item.quantity} x €{item.price.toFixed(2)}
              </Typography>
            </Box>
          ))}
        <Divider sx={{ my: 2 }} />
        <Typography>
          <strong>Total: €{billDetails.total_amount.toFixed(2)}</strong>
        </Typography>

        {/* Payment Options Button and Menu */}
        <StyledButton
          aria-controls="payment-options-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          variant="contained"
          color="primary"
        >
          Pay Bill
        </StyledButton>
        <Menu
          id="payment-options-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {paymentOptions.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handlePaymentOptionSelect(option)}
            >
              {option.replace(/_/g, ' ').toUpperCase()}
            </MenuItem>
          ))}
        </Menu>

        <StyledButton onClick={onClose} variant="outlined" color="secondary">
          Close
        </StyledButton>
      </DialogContent>
    </Dialog>
  );
};

export default BillReviewModal;
