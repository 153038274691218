import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { fetchUserProfile, fetchAreasForRestaurant, fetchTablesForArea } from '../../services/apiService';

const QRCodeComponent = () => {
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [defaultRestaurant, setDefaultRestaurant] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');

        const loadAreasAndTables = async (restaurantId) => {
            try {
                const fetchedAreas = await fetchAreasForRestaurant(restaurantId, token);

                let collectedTables = [];
                for (const area of fetchedAreas) {
                    const tablesForArea = await fetchTablesForArea(area.id, token);
                    collectedTables = [...collectedTables, ...tablesForArea.map(table => ({ ...table, area_id: area.id }))];
                }
                setTables(collectedTables);
            } catch (error) {
                setError('Error loading areas or tables');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        const loadUserProfile = async () => {
            try {
                const userProfile = await fetchUserProfile(token);
                if (userProfile.default_restaurant) {
                    setDefaultRestaurant(userProfile.default_restaurant);
                    await loadAreasAndTables(userProfile.default_restaurant);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setError('Error loading user profile');
                console.error('Error:', error);
                setLoading(false);
            }
        };

        if (token) {
            loadUserProfile();
        } else {
            console.error('No token found');
            setLoading(false); // Stop loading if no token is found
        }
    }, []);

    const getQRCodeValue = (table) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        return `${baseUrl}users/qr-redirect/${table.id}`;
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    if (!defaultRestaurant) {
        return <p>No default restaurant found. Please select a restaurant to view tables and QR codes.</p>;
    }

    return (
        <div>
            {tables.length > 0 ? (
                tables.map(table => (
                    <div key={table.id}>
                        <h3>Table {table.number}</h3>
                        <QRCode
                            value={getQRCodeValue(table)}
                            size={128}  // Customize size
                            bgColor="#ffffff"  // Customize background color
                            fgColor="#3e3effe3"  // Customize foreground color
                            level="H"  // Customize error correction level
                        />
                    </div>
                ))
            ) : (
                <p>No tables found</p>
            )}
        </div>
    );
};

export default QRCodeComponent;
