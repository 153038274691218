import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { usePOS } from '../../../contexts/POSContext';
import { updateOrderItemNote } from '../../../services/posApiService';
import { Modal, Typography, TextField, Button, Box } from '@mui/material';
import styled from 'styled-components';

const ModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #121212;
  border: 2px solid #ff6a3ee3;
  box-shadow: 24;
  padding: 16px;
  outline: none;
`;

const StyledTextField = styled(TextField)`
  && {
    margin-top: 16px;
    width: 100%;
  }
  .MuiInputBase-root {
    color: white;
  }
  .MuiFormLabel-root {
    color: #ff6a3ee3;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #ff6a3ee3;
    }
    &:hover fieldset {
      border-color: #ff6a3ee3;
    }
    &.Mui-focused fieldset {
      border-color: #ff6a3ee3;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    margin-top: 16px;
    width: 100%;
  }
`;

const ItemDetails = ({ open, handleClose }) => {
  const { token } = useAuth();
  const { showAlert, currentItem, setSelectedItems, selectedItems, setFetchedItems } = usePOS();
  const [note, setNote] = useState('');

  useEffect(() => {
    if (currentItem) {
      setNote(currentItem.note || '');
    }
  }, [currentItem]);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSaveOrUpdateNote = async () => {
    try {
      if (!currentItem?.order_item_id) {
        // Update the note for new items before order creation
        const updatedItems = selectedItems.map(item =>
          item.id === currentItem.id ? { ...item, note } : item
        );
        setSelectedItems(updatedItems);
        showAlert('Note added to the new item successfully', 'success');
      } else {
        // Update the note for existing items after order creation
        const payload = {
          note: note,
          menu_item: currentItem.menu_item_id,
          combo: currentItem.combo_id
        };
        const response = await updateOrderItemNote(currentItem.order_item_id, payload, token);
        if (response) {
          // Directly update the note in the fetchedItems state
          setFetchedItems(prevFetchedItems => {
            const updatedItems = { ...prevFetchedItems.items };
            for (const course in updatedItems) {
              for (const itemName in updatedItems[course]) {
                if (updatedItems[course][itemName].id === currentItem.order_item_id) {
                  updatedItems[course][itemName] = {
                    ...updatedItems[course][itemName],
                    note
                  };
                }
              }
            }
            return { ...prevFetchedItems, items: updatedItems };
          });
          showAlert('Note updated successfully', 'success');
        }
      }
    } catch (error) {
      console.error('Error saving/updating note:', error);
      showAlert('Error saving/updating note', 'error');
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <Typography variant="h6" style={{ color: '#ff6a3ee3' }}>
          Edit Item Details
        </Typography>
        <StyledTextField
          label="Note"
          variant="outlined"
          multiline
          rows={4}
          value={note}
          onChange={handleNoteChange}
        />
        <StyledButton variant="contained" color="primary" onClick={handleSaveOrUpdateNote}>
          Save
        </StyledButton>
      </ModalContainer>
    </Modal>
  );
};

export default ItemDetails;
