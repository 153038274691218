import React, { useState } from 'react';
import {
  Stepper, Step, StepLabel, Button, Typography, CircularProgress, TextField, Alert
} from '@mui/material';
import styled from 'styled-components';
import { createRestaurant } from '../../../services/apiService';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import PictureUpload from '../../Common/PictureUpload';

const steps = ['Restaurant Details', 'Restaurant Info', 'Extras'];

const WizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 2rem 0;
`;

const InputContainer = styled.div`
  width: 65%;
  margin-bottom: 1rem;
`;

const DescriptionText = styled(Typography)`
  color: darkgrey;
  margin-bottom: 0.5rem;
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const RestaurantWizard = ({ onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [restaurantName, setRestaurantName] = useState('');
  const [description, setDescription] = useState('');
  const [legalAddress, setLegalAddress] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [logo, setLogo] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null);
  const [previewCoverImageUrl, setPreviewCoverImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const { loadUserRestaurants } = useRestaurant();

  const handleFinish = async () => {
    setLoading(true);
    setAlertMessage(null);
    const token = localStorage.getItem('access_token');
    const formData = new FormData();
    formData.append('name', restaurantName);
    formData.append('description', description);
    formData.append('legal_address', legalAddress);
    formData.append('shipping_address', shippingAddress);
    formData.append('vat_number', vatNumber);
    if (logo) formData.append('restaurant_logo', logo);
    if (coverImage) formData.append('cover_image', coverImage);

    try {
      const newRestaurant = await createRestaurant(formData, token);
      loadUserRestaurants();
      onComplete(newRestaurant.id);
    } catch (error) {
      setAlertMessage({ type: 'error', text: 'Error creating restaurant. Please try again.' });
      console.error('Error creating restaurant:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setAlertMessage(null);
    if (activeStep === 0 && !restaurantName.trim()) {
      setAlertMessage({ type: 'warning', text: 'Restaurant name is required' });
      return;
    }
    if (activeStep === 1 && (!legalAddress.trim() || !vatNumber.trim())) {
      setAlertMessage({ type: 'warning', text: 'Legal address and VAT number are required' });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileSelectLogo = (file, previewUrl) => {
    setLogo(file);
    setPreviewLogoUrl(previewUrl);
  };

  const handleFileSelectCoverImage = (file, previewUrl) => {
    setCoverImage(file);
    setPreviewCoverImageUrl(previewUrl);
  };

  const handleClearLogo = () => {
    setLogo(null);
    setPreviewLogoUrl(null);
  };

  const handleClearCoverImage = () => {
    setCoverImage(null);
    setPreviewCoverImageUrl(null);
  };

  return (
    <WizardContainer>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{ color: 'grey' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {loading ? (
        <StepContainer>
          <CircularProgress />
        </StepContainer>
      ) : (
        <StepContainer>
          {alertMessage && (
            <Alert severity={alertMessage.type} sx={{ width: '65%', marginBottom: '1rem' }}>
              {alertMessage.text}
            </Alert>
          )}
          {activeStep === steps.length ? (
            <Typography>All steps completed - you're finished</Typography>
          ) : (
            <>
              {activeStep === 0 && (
                <InputContainer>
                  <DescriptionText variant="body2">Add a name to your restaurant</DescriptionText>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Restaurant Name"
                    value={restaurantName}
                    onChange={(e) => setRestaurantName(e.target.value)}
                    margin="normal"
                  />
                  <DescriptionText variant="body2">Add a description for your restaurant (optional)</DescriptionText>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                    multiline
                  />
                </InputContainer>
              )}
              {activeStep === 1 && (
                <>
                  <InputContainer>
                    <DescriptionText variant="body2">Add the legal address of your restaurant</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Legal Address"
                      value={legalAddress}
                      onChange={(e) => setLegalAddress(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Add the shipping address of your restaurant (optional)</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Shipping Address"
                      value={shippingAddress}
                      onChange={(e) => setShippingAddress(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Add the VAT number of your restaurant</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="VAT Number"
                      value={vatNumber}
                      onChange={(e) => setVatNumber(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <InputContainer>
                    <DescriptionText variant="body2">Upload a logo for your restaurant (optional)</DescriptionText>
                    <PictureUpload
                      existingFile={null}
                      previewUrl={previewLogoUrl}
                      onFileSelect={handleFileSelectLogo}
                      onClearImage={handleClearLogo}
                    />
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Upload a cover image for your restaurant (optional)</DescriptionText>
                    <PictureUpload
                      existingFile={null}
                      previewUrl={previewCoverImageUrl}
                      onFileSelect={handleFileSelectCoverImage}
                      onClearImage={handleClearCoverImage}
                    />
                  </InputContainer>
                </>
              )}
            </>
          )}
        </StepContainer>
      )}
      <BottomButtonsContainer>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
        >
          Back
        </Button>
        <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} variant="contained" color="primary">
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </BottomButtonsContainer>
    </WizardContainer>
  );
};

export default RestaurantWizard;
