import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TextField, Button, Typography, Alert } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { loginUser } from '../../services/apiService';

// Styled components for flexbox containers
const Container = styled.div`
  padding: 4% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 4% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 4% 35% 2% 35%;
  }
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// Login form component
const LoginForm = () => {
  // State Management for Form Inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loginError, setLoginError] = useState('');

  const navigate = useNavigate(); // Hook to navigate
  const { login } = useAuth();

  // Input Handlers
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // Form Validation
  const validateForm = () => {
    const errors = {};
    // Simply check if the email field is not empty
    if (!email) {
      errors.email = 'Please enter your email';
    }
    // Simply check if the password field is not empty
    if (!password) {
      errors.password = 'Please enter your password';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Form Submission handling
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const userData = { email, password };
      try {
        const response = await loginUser(userData);
        const { access_token, refresh_token, roles } = response.data;
        storeTokens(access_token, refresh_token);
        login(access_token, roles); // Set the token and roles in the AuthContext
        navigate('/dashboard/my-restaurants'); // Navigate to the dashboard immediately after login
      } catch (error) {
        const message = error.response && error.response.data
          ? error.response.data.non_field_errors
            ? error.response.data.non_field_errors.join(' ')
            : error.response.data.error || 'An error occurred. Please try again.'
          : 'An error occurred. Please try again.';
        setLoginError(message);
      }
    }
  };

  // Utility function for token management
  const storeTokens = (accessToken, refreshToken) => {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  };

  return (
    <Container>
      <Intro>
        <Typography variant="h3" style={{ paddingBottom: '10px' }}>Log in</Typography>
        <Typography variant="body1" style={{ paddingBottom: '10px' }}>New to TaboMenu? <a href="/register">Register</a></Typography>
      </Intro>
      <Form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="email"
          label="Email"
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          variant="outlined"
          error={!!formErrors.email}
          helperText={formErrors.email}
          autoComplete="email"
        />
        <TextField
          fullWidth
          type="password"
          label="Password"
          value={password}
          onChange={handlePasswordChange}
          margin="normal"
          variant="outlined"
          error={!!formErrors.password}
          helperText={formErrors.password}
          autoComplete="current-password"
        />
        {loginError && <Alert severity="error" style={{ width: '100%', marginTop: '10px' }}>{loginError}</Alert>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Log in
        </Button>
        <ForgotPassword>
          <Typography variant="body1" style={{ paddingTop: '15px' }}><a href="/password-reset">Forgot your password?</a></Typography>
        </ForgotPassword>
      </Form>
    </Container>
  );
};

export default LoginForm;
