import React, { createContext, useState, useContext, useCallback } from 'react';
import {
  fetchUserProfile, fetchUserRestaurants, fetchAreasForRestaurant, fetchMenus, fetchMenuCategories, fetchMenuItemsByCategory,
  fetchCombosByCategory, fetchAllergens, fetchDietGroups, addTableToArea } from '../services/apiService';
import { fetchUserRestaurantDetails } from '../services/usersApiService';

const RestaurantContext = createContext();

export const useRestaurant = () => useContext(RestaurantContext);

export const RestaurantProvider = ({ children }) => {
    // State definitions
    const [token, setToken] = useState(() => localStorage.getItem('access_token'));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [restaurantId, setRestaurantId] = useState(null);
    const [userRestaurants, setUserRestaurants] = useState([]);
    const [showSetupWizard, setShowSetupWizard] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [editingRestaurant, setEditingRestaurant] = useState(null);
    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState(null);
    const [editingArea, setEditingArea] = useState(null);
    const [tables, setTables] = useState([]);
    const [refreshTables, setRefreshTables] = useState(0);
    const [floorMapPrompts, setFloorMapPrompts] = useState({});
    const [menus, setMenus] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [editingMenu, setEditingMenu] = useState(null);
    const [allCategories, setAllCategories] = useState([]);
    const [menuCategories, setMenuCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [editingCategory, setEditingCategory] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [allMenuItems, setAllMenuItems] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [editingMenuItem, setEditingMenuItem] = useState(null);
    const [combos, setCombos] = useState([]);
    const [editingCombo, setEditingCombo] = useState(false);
    const [refreshCombos, setRefreshCombos] = useState(false);
    const [allergens, setAllergens] = useState([]);
    const [dietGroups, setDietGroups] = useState([]);
    const [selectedAllergens, setSelectedAllergens] = useState([]);
    const [selectedDietGroups, setSelectedDietGroups] = useState([]);
    const [allergenOptions, setAllergenOptions] = useState([]);
    const [dietGroupOptions, setDietGroupOptions] = useState([]);
    const [showSidebar, setShowSidebar] = useState(false);
    const [sidebarContext, setSidebarContext] = useState('');
    const [previewData, setPreviewData] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [restaurantDetails, setRestaurantDetails] = useState(null);
    const [currentOrderId, setCurrentOrderId] = useState(() => localStorage.getItem('currentOrderId'));

    // ***** Functions that manipulate the state *****

    // Function to fetch areas for a specific restaurant and decide if the floor map prompt should be shown
    const checkForFloorMap = useCallback(async (restaurantId) => {
      const token = localStorage.getItem('access_token');
      try {
        const fetchedAreas = await fetchAreasForRestaurant(restaurantId, token);
        setFloorMapPrompts(prevPrompts => ({
          ...prevPrompts,
          [restaurantId]: fetchedAreas.length === 0
        }));
        setAreas(fetchedAreas); // Set the fetched areas
        if (fetchedAreas.length > 0) {
          // Automatically select the first area if available
          setSelectedArea(fetchedAreas[0].id);
        }
      } catch (error) {
        console.error('Error fetching areas for restaurant:', error);
      }
    }, []);

    // Function to load user restaurants and set the selected one
    const loadUserRestaurants = useCallback(async () => {
      const token = localStorage.getItem('access_token');
      try {
        const restaurants = await fetchUserRestaurants(token);
        setUserRestaurants(restaurants);

        const userProfile = await fetchUserProfile(token);
        let defaultRestaurantId = userProfile.default_restaurant;

        if (!defaultRestaurantId && restaurants.length > 0) {
          defaultRestaurantId = restaurants[0].id;
        }

        if (defaultRestaurantId) {
          setSelectedRestaurant(defaultRestaurantId);
          await checkForFloorMap(defaultRestaurantId);
        } else {
          setShowSetupWizard(true);
        }
      } catch (error) {
        console.error('Error fetching user restaurants:', error);
      }
    }, [checkForFloorMap]);

    // Function to load users menu
    const loadUserMenus = useCallback(async () => {
      const token = localStorage.getItem('access_token');
      try {
        // Fetch all menus
        const allMenus = await fetchMenus(token);
        setMenus(allMenus);
      } catch (error) {
        console.error('Error loading menus:', error);
      }
    }, [setMenus]);

    // Function to load categories for the selected menu
    const loadMenuCategories = useCallback(async (selectedMenu) => {
      if (selectedMenu) {
        const token = localStorage.getItem('access_token');
        try {
          const categories = await fetchMenuCategories(selectedMenu, token);
          setMenuCategories(categories);
        } catch (error) {
          console.error('Error loading menu categories:', error);
          // Handle error appropriately
        }
      }
    }, []); // Add any dependencies if necessary

    // Function to load menu items for the selected menu
    const loadMenuItems = useCallback(async (selectedMenu) => {
      if (selectedMenu) {
        setLoading(true); // Assuming you have a setLoading function to manage UI loading state
        try {
          // Fetch categories for the selected menu
          const categories = await fetchMenuCategories(selectedMenu);
          let allItems = [];

          // Fetch items for each category and aggregate them
          for (const category of categories) {
            const items = await fetchMenuItemsByCategory(category.id);
            allItems = [...allItems, ...items];
          }

          setMenuItems(allItems); // Update state with aggregated items
        } catch (error) {
          console.error('Error loading menu items:', error);
        } finally {
          setLoading(false); // Ensure loading state is reset regardless of success/failure
        }
      }
    }, [setLoading, setMenuItems]);

    // Function to load combos for the selected menu
    const loadCombos = useCallback(async (selectedMenu) => {
      if (selectedMenu) {
        setLoading(true);
        try {
          // Fetch categories for the selected menu
          const categories = await fetchMenuCategories(selectedMenu);
          let allCombos = [];

          // Fetch combos for each category and aggregate them
          for (const category of categories) {
            const combos = await fetchCombosByCategory(category.id);
            allCombos = [...allCombos, ...combos];
          }

          setCombos(allCombos); // Update state with aggregated combos
        } catch (error) {
          console.error('Error loading combos:', error);
        } finally {
          setLoading(false); // Ensure loading state is reset regardless of success/failure
        }
      }
    }, [setLoading, setCombos]); // Dependency array to include any state setters used in the callback

    // Function to fetch allergens and diet groups
    const fetchAllergensAndDietGroups = useCallback(async () => {
      try {
        const token = localStorage.getItem('access_token');
        const allergensData = await fetchAllergens(token);
        const dietGroupsData = await fetchDietGroups(token);
        setAllergens(allergensData);
        setDietGroups(dietGroupsData);
      } catch (error) {
        console.error('Error fetching allergens or diet groups:', error);
      }
    }, []);

    // Function to update a restaurant after editing
    const updateEditedRestaurant = (updatedRestaurant) => {
      const updatedRestaurants = userRestaurants.map(restaurant =>
        restaurant.id === updatedRestaurant.id ? updatedRestaurant : restaurant
      );
      setUserRestaurants(updatedRestaurants);
      setSelectedRestaurant(updatedRestaurant.id);
    };

    // Function to handle new area creation
    const handleNewAreaCreated = async (newAreaId) => {
      setSelectedArea(newAreaId); // Set the selected area
      await checkForFloorMap(selectedRestaurant); // Refresh areas
      setShowSidebar(false); // Close the sidebar after creating the area
    };

    // Function to create a new table
    const handleAddTable = async (areaId) => {
        const token = localStorage.getItem('access_token');
        try {
            await addTableToArea(areaId, token);
            setRefreshTables(prev => prev + 1);
        } catch (error) {
            console.error('Error adding table:', error);
        }
    };

    // Function to create a new menu
    const addNewMenu = (newMenu) => {
      setMenus(prevMenus => [...prevMenus, newMenu]);
      setSelectedMenu(newMenu.id);
    };

    // Function to update menu after creation
    const reloadMenus = async () => {
      setLoading(true);
      const token = localStorage.getItem('access_token');
      const response = await fetchMenus(token);
      const sortedMenus = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setMenus(sortedMenus);

      setLoading(false);
    };

    // Function to update a menu after editing
    const updateEditedMenu = (updatedMenu) => {
      setMenus(prevMenus => prevMenus.map(menu => menu.id === updatedMenu.id ? updatedMenu : menu));
      setShowSidebar(false);
      setEditingMenu(null);
    };

    // Function to create a new category
    const addNewCategory = (newCategory, onCategoryAdded) => {
      setMenuCategories(prevCategories => [...prevCategories, newCategory]);
      if (onCategoryAdded) {
        onCategoryAdded(newCategory);
      }
    };

    // Function to update a category after editing
    const updateCategories = (updatedCategory) => {
      setMenuCategories(prevCategories => prevCategories.map(cat => cat.id === updatedCategory.id ? updatedCategory : cat));
    };

    // Function to update menu items after creation
    const addNewMenuItem = (newMenuItem) => {
      setMenuItems(prevItems => [...prevItems, newMenuItem]);
    };

    // Function to update menu items after editing
    const updateMenuItems = (updatedMenuItem) => {
      setMenuItems(prevItems => prevItems.map(item => item.id === updatedMenuItem.id ? updatedMenuItem : item));
    };

    // Function to update a combo after creation
    const addNewCombo = (newCombo) => {
      setRefreshCombos(prev => !prev); // Toggle to trigger re-fetching
    };

    // Function to update a combo after editing
    const updateCombos = (updatedCombo) => {
      setRefreshCombos(prev => !prev); // Toggle to trigger re-fetching
    };

    // Function to fetch and set restaurant details (TO BE MOVED IN USERCONTEXT?)
    const fetchAndSetRestaurantDetails = async (restaurantId) => {
      try {
        const details = await fetchUserRestaurantDetails(restaurantId);
        setRestaurantDetails(details);
      } catch (error) {
        console.error('Error fetching restaurant details:', error);
      }
    };

    // Function to update the current OrderId
    const updateCurrentOrderId = (orderId) => {
      setCurrentOrderId(orderId);
      // Set order id in local storage
      localStorage.setItem('currentOrderId', orderId);
    };

    // Function to close the sidebar
    const closeSidebar = () => {
      setShowSidebar(false);
      setSelectedCategory(null);
    };

    // Definitions
    const value = {
        // State declarations
        token, setToken,
        error, setError,
        restaurantId, setRestaurantId,
        userRestaurants, setUserRestaurants,
        showSetupWizard, setShowSetupWizard,
        selectedRestaurant, setSelectedRestaurant,
        editingRestaurant,setEditingRestaurant,
        areas, setAreas,
        selectedArea,setSelectedArea,
        editingArea, setEditingArea,
        tables, setTables,
        refreshTables, setRefreshTables,
        floorMapPrompts, setFloorMapPrompts,
        menus, setMenus,
        selectedMenu, setSelectedMenu,
        selectedMenus, setSelectedMenus,
        editingMenu, setEditingMenu,
        allCategories, setAllCategories,
        menuCategories, setMenuCategories,
        selectedCategory, setSelectedCategory,
        editingCategory, setEditingCategory,
        menuItems, setMenuItems,
        allMenuItems, setAllMenuItems,
        selectedMenuItem, setSelectedMenuItem,
        editingMenuItem, setEditingMenuItem,
        combos, setCombos,
        editingCombo, setEditingCombo,
        refreshCombos, setRefreshCombos,
        allergens, setAllergens,
        dietGroups, setDietGroups,
        selectedAllergens, setSelectedAllergens,
        selectedDietGroups, setSelectedDietGroups,
        allergenOptions, setAllergenOptions,
        dietGroupOptions, setDietGroupOptions,
        showSidebar, setShowSidebar,
        sidebarContext, setSidebarContext,
        loading, setLoading,
        previewData, setPreviewData,
        showPreview, setShowPreview,
        restaurantDetails, setRestaurantDetails,
        currentOrderId, setCurrentOrderId,
        // State-related functions
        closeSidebar,
        checkForFloorMap,
        loadUserRestaurants,
        loadUserMenus,
        updateEditedRestaurant,
        handleNewAreaCreated,
        handleAddTable,
        addNewMenu,
        reloadMenus,
        updateEditedMenu,
        loadMenuCategories,
        addNewCategory,
        updateCategories,
        loadMenuItems,
        addNewMenuItem,
        updateMenuItems,
        loadCombos,
        fetchAllergensAndDietGroups,
        addNewCombo,
        updateCombos,
        fetchAndSetRestaurantDetails,
        updateCurrentOrderId
    };

    return (
        <RestaurantContext.Provider value={value}>
            {children}
        </RestaurantContext.Provider>
    );
};
