// RestaurantOverview.jsx
import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Dialog, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { updateUserDefaultRestaurant, deleteRestaurant } from '../../services/apiService';
import SidebarWidget from './SidebarWidget';
import ConfirmationModal from './ConfirmationModal';
import RestaurantWizard from './restaurant/RestaurantWizard';
import RestaurantHeader from './restaurant/RestaurantHeader';
import AreaSwiper from './restaurant/AreaSwiper';

const Container = styled.div`
  padding: 20px;
`;

const Button = styled.button`
  background-color: #3e3effe6; // primary color
  font-weight: bold;
  color: white;
  text-align: center;
  border: none;
  border-radius: 30px;
  padding: 8px 8px;
  cursor: pointer;
  margin-top: 20px;
  width: 90%; // Full width on mobile
  display: block;
  margin: 10px auto;

  @media (min-width: 768px) {
    width: 45%;
  }

  @media (min-width: 1024px) {
    width: 25%;
  }

  transition: background-color 0.3s ease; // smooth transition for background color

  &:hover {
    background-color: #ff6a3ee6; // secondary color
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const RestaurantOverview = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState(null);
  const [currentAreaName, setCurrentAreaName] = useState('');
  const [loading, setLoading] = useState(true);

  const {
    setShowSidebar, setSidebarContext, userRestaurants, checkForFloorMap, loadUserRestaurants,
    selectedRestaurant, setSelectedRestaurant, areas, setFloorMapPrompts,
    setEditingArea, showSetupWizard, setShowSetupWizard, floorMapPrompts, setEditingRestaurant
  } = useRestaurant();

  const restaurantOptions = userRestaurants.map(restaurant => ({
    value: restaurant.id,
    label: restaurant.name
  }));

  useEffect(() => {
    const loadRestaurantsAndAreas = async () => {
      setLoading(true);
      await loadUserRestaurants();
      setLoading(false);
    };

    loadRestaurantsAndAreas();
  }, [loadUserRestaurants]);

  const handleRestaurantChange = (selectedOption) => {
    setSelectedRestaurant(selectedOption.value);
  };

  const handleToggleDefault = async () => {
    if (selectedRestaurant) {
      await updateUserDefaultRestaurant(selectedRestaurant);
    }
  };

  const handleNewRestaurantCreated = (newRestaurantId) => {
    setSelectedRestaurant(newRestaurantId);
    loadUserRestaurants();
    setFloorMapPrompts(prev => ({ ...prev, [newRestaurantId]: true }));
  };

  const openSidebarForEditing = (restaurantId) => {
    const restaurantToEdit = userRestaurants.find(restaurant => restaurant.id === restaurantId);
    if (restaurantToEdit) {
      setEditingRestaurant(restaurantToEdit);
      setSidebarContext('editRestaurant');
      setShowSidebar(true);
    } else {
      console.error('Restaurant not found for editing');
    }
  };

  const openSidebarForAreaCreation = () => {
    setEditingArea(null);
    setSidebarContext('createArea');
    setShowSidebar(true);
  };

  const toggleDeleteModal = (restaurantId) => {
    setRestaurantToDelete(restaurantId);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteRestaurant = async () => {
    if (!restaurantToDelete) return;

    const token = localStorage.getItem('access_token');
    try {
      await deleteRestaurant(restaurantToDelete, token);
      setRestaurantToDelete(null);
      setShowDeleteModal(false);
      loadUserRestaurants();
    } catch (error) {
      console.error('Error deleting restaurant:', error);
    }
  };

  useEffect(() => {
    if (selectedRestaurant) {
      checkForFloorMap(selectedRestaurant);
    }
  }, [selectedRestaurant, checkForFloorMap]);

  useEffect(() => {
    const fetchAreasAndTables = async () => {
      if (selectedRestaurant) {
        await checkForFloorMap(selectedRestaurant);
      }
    };

    fetchAreasAndTables();
  }, [selectedRestaurant, checkForFloorMap]);

  useEffect(() => {
    if (areas.length > 0) {
      setCurrentAreaName(areas[0].name);
    }
  }, [areas]);

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress size={60} color="secondary" />
      </LoadingContainer>
    );
  }

  return (
    <>
      <SidebarWidget />

      <RestaurantHeader
        title="Restaurant Overview"
        restaurantOptions={restaurantOptions}
        handleRestaurantChange={handleRestaurantChange}
        selectedRestaurant={selectedRestaurant}
        setShowSetupWizard={setShowSetupWizard}
        openSidebarForAreaCreation={openSidebarForAreaCreation}
        openSidebarForEditing={openSidebarForEditing}
        toggleDeleteModal={toggleDeleteModal}
        handleToggleDefault={handleToggleDefault}
      />

      {showDeleteModal && (
        <ConfirmationModal
          onClose={() => toggleDeleteModal(null)}
          onConfirm={handleDeleteRestaurant}
        />
      )}

      <Container>
        {showSetupWizard && (
          <Dialog
            open={showSetupWizard}
            onClose={() => setShowSetupWizard(false)}
            fullWidth
            maxWidth="md"
            PaperProps={{
              style: { height: '85%', display: 'flex', flexDirection: 'column', justifyContent: 'center' },
            }}
          >
            <DialogTitle>Create Your Restaurant</DialogTitle>
            <DialogContent>
              <RestaurantWizard
                onComplete={(newRestaurantId) => {
                  setShowSetupWizard(false);
                  handleNewRestaurantCreated(newRestaurantId);
                }}
              />
            </DialogContent>
          </Dialog>
        )}

        {floorMapPrompts[selectedRestaurant] && (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>Create a floor map for your restaurant</h3>
            <p>The floor map will include tables and relative seatings</p>
            <Button onClick={openSidebarForAreaCreation}>Add Area</Button>
          </div>
        )}

        {selectedRestaurant && areas.length > 0 && (
          <AreaSwiper
            currentAreaName={currentAreaName}
            setCurrentAreaName={setCurrentAreaName}
          />
        )}
      </Container>
    </>
  );
};

export default RestaurantOverview;
