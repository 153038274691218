import api from './apiInstance'; // Import the Axios instance with interceptors

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Helper function to get the CSRF token from the cookie
const getCsrfToken = () => {
  const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];
  return csrfToken;
};

// Function to register user
export const registerUser = (userData) => {
  return api.post('auth/register/', userData)
    .then(response => response.data);
};

// Function to login user
export const loginUser = (userData) => {
  return api.post(`${API_BASE_URL}auth/login/`, userData)
    .then(response => {
      console.log("Login Response:", response.data); // Log the response
      return response;
    });
};

// Function to logout user
export const logoutUser = async () => {
  const url = `${API_BASE_URL}auth/logout/`;
  const accessToken = localStorage.getItem('access_token');
  const refreshToken = localStorage.getItem('refresh_token');
  try {
    const response = await api.post(
      url,
      { refresh_token: refreshToken },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

// Function to get an email to reset password
export const resetPasswordEmail = async (email) => {
  const url = `${API_BASE_URL}auth/password_reset/`;
  const csrfToken = getCsrfToken(); // Get CSRF token from cookies

  // Include the CSRF token in the request headers
  const response = await api.post(url, { email }, {
    headers: {
      'X-CSRFToken': csrfToken,
    },
  });

  return response.data;
};

// Function to verify email
export const verifyEmail = (uuid) => {
  return api.get(`${API_BASE_URL}auth/verify_email/${uuid}/`);
};

// Function to reset password
export const resetPassword = (resetPasswordEndpoint, newPasswordData) => {
  return api.post(resetPasswordEndpoint, newPasswordData);
};

// Function to login into the POS System
export const loginStaff = async (uniqueIdentifier, passcode) => {
    const url = `${API_BASE_URL}auth/staff-login/`;
    try {
        const response = await api.post(url, {
            unique_identifier: uniqueIdentifier,
            passcode: passcode,
        });
        // Here, you don't store tokens in local storage. Instead, manage authentication state.
        // For instance, update the application state to reflect that the user is logged in.
        return response.data;  // This might only be necessary for immediate post-login actions, not for storing tokens.
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

// Function to update Staff Passcode
export const updateStaffPasscode = async (newPasscode, token) => {
    const url = `${API_BASE_URL}auth/update-passcode/`;
    const payload = {
        new_passcode: newPasscode // Make sure this is the new passcode entered by the user
    };
    console.log("Sending payload for passcode update:", payload); // Confirm payload structure

    try {
        const response = await api.post(url, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error updating passcode:', error.response?.data);
        throw error;
    }
};

// Function for staff to clock-out / logout
export const staffLogout = async () => {
    const url = `${API_BASE_URL}auth/staff-logout/`;
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    try {
        const response = await api.post(
            url,
            { refresh_token: refreshToken },
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
};

// Function to fetch user profile
export const fetchUserProfile = async () => {
  const url = `${API_BASE_URL}auth/user/profile/`;
  const token = localStorage.getItem('access_token');

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Function to update user's default restaurant
export const updateUserDefaultRestaurant = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/user/profile/`;
  const token = localStorage.getItem('access_token');

  try {
    await api.patch(url, {
      default_restaurant: restaurantId
    }, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    console.error('Error updating default restaurant:', error);
    throw error; // Re-throw the error for handling in the component
  }
};

// Function to fetch restaurants
export const fetchUserRestaurants = async (token) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/restaurants/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data; // Assuming the API returns an array of restaurants
  } catch (error) {
    console.error('Error fetching user restaurants:', error);
    throw error;
  }
};

// Function to fetch menus for a specific restaurant
export const fetchMenusForRestaurant = async (restaurantId, token) => {
  const response = await api.get(`${API_BASE_URL}api/menu/?restaurant_id=${restaurantId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

// Function to fetch menus
export const fetchMenus = async () => {
  const url = `${API_BASE_URL}api/menu/`;
  const token = localStorage.getItem('access_token');
  
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // handle error
    console.error('Error fetching menus:', error);
    throw error; // Re-throw the error for handling in the component
  }
};

// Function to fetch menu items by category
export const fetchMenuItemsByCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/menu-items/?category_id=${categoryId}`;
  const token = localStorage.getItem('access_token');
  
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items by category:', error);
    throw error;
  }
};

// Function to fetch menu items and combos by category
export const fetchMenuItemsAndCombosByCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/menu-items-and-combos/${categoryId}/`;
  const token = localStorage.getItem('access_token');
  
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items and combos by category:', error);
    throw error;
  }
};

// Function to fetch details for a specific category, including its associated menus
export const fetchCategoryDetails = async (categoryId, token) => {
  try {
    const url = `${API_BASE_URL}api/menu-categories/${categoryId}/`; // Note the specific category ID in the path

    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data; // This should include the category details along with associated menus
  } catch (error) {
    console.error(`Error fetching details for category ${categoryId}:`, error);
    throw error;
  }
};

// Function to fetch categories for a specific restaurant, optionally filtering out categories already assigned to a selected menu
export const fetchCategoriesForRestaurant = async (restaurantId, token, selectedMenuId = null) => {
  try {
    // Construct the base URL with required restaurant_id query parameter
    let url = `${API_BASE_URL}api/menu-categories/?restaurant_id=${restaurantId}`;

    // Append selectedMenuId as a query parameter if provided
    if (selectedMenuId !== null) {
      url += `&selectedMenuId=${selectedMenuId}`;
    }

    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching categories for restaurant:', error);
    throw error;
  }
};

// Function to fetch categories available for association
export const fetchCategoriesAvailableForAssociation = async (selectedMenu, token) => {
  const url = `${API_BASE_URL}api/menu-categories/available_for_association/?selectedMenu=${selectedMenu}`;

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories available for association:', error);
    throw error;
  }
};

// Function to fetch menu items for a specific restaurant, optionally filtered by category
export const fetchMenuItemsForRestaurant = async (restaurantId, token, selectedCategoryId = null) => {
  let url = `${API_BASE_URL}api/menu-items/?restaurant_id=${restaurantId}`;

  if (selectedCategoryId !== null) {
    url += `&category_id=${selectedCategoryId}`;
  }

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items for restaurant:', error);
    throw error;
  }
};

// Function to fetch menu items available for association
export const fetchMenuItemsAvailableForAssociation = async (selectedMenu, selectedCategory, token) => {
  let url = `${API_BASE_URL}api/menu-items/available_for_association/?selectedMenu=${selectedMenu}`;

  if (selectedCategory) {
    url += `&selectedCategory=${selectedCategory}`;
  }

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items available for association:', error);
    throw error;
  }
};

// Function to dissociate a category from a menu
export const dissociateCategoryFromMenu = async (category_id, menu_id, token) => {
    try {
        const response = await api.post(`${API_BASE_URL}api/menu-category/${category_id}/dissociate-menu/`, { menu_id }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error('Error dissociating category from menu:', error);
        throw error;
    }
};

// Function to fetch a single category by ID including its menu associations
export const fetchCategoryById = async (categoryId, token) => {
  const url = `${API_BASE_URL}api/menu-categories/${categoryId}/`;
  
  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching category by ID: ${categoryId}`, error);
    throw error;
  }
};

// Function to fetch Combo details
export const fetchComboDetails = async (comboId, token) => {
  const url = `${API_BASE_URL}api/combos/${comboId}/get_combo_by_id/`;

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching combo details:', error);
    throw error;
  }
};

// Function to create a restaurant
export const createRestaurant = async (restaurantData, token, setAsDefault = false) => {
  if (setAsDefault) {
    restaurantData.set_as_default = true;
  }
  try {
    const response = await api.post(`${API_BASE_URL}api/restaurants/`, restaurantData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating restaurant:', error);
    throw error;
  }
};

// Function to update an existing restaurant
export const updateRestaurant = async (restaurantId, restaurantData, token) => {
  try {
    const response = await api.put(`${API_BASE_URL}api/restaurants/${restaurantId}/`, restaurantData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating restaurant:', error);
    throw error;
  }
};

// Function to delete an existing restaurant
export const deleteRestaurant = async (restaurantId, token) => {
  const url = `${API_BASE_URL}api/restaurants/${restaurantId}/`;

  try {
    await api.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    console.error('Error deleting restaurant:', error);
    throw error;
  }
};

// Function to fetch areas for a specific restaurant
export const fetchAreasForRestaurant = async (restaurantId, token) => {
  const response = await api.get(`${API_BASE_URL}api/areas/?restaurant_id=${restaurantId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

// Function to fetch the details of an existing restaurant area
export const fetchArea = async (areaId, token) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/`;

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching area:', error);
    throw error;
  }
};

// Function to create a new restaurant area
export const createArea = async (areaData, restaurantId, token) => {
  const url = `${API_BASE_URL}api/areas/`; // Adjust the endpoint as per your actual API URL
  const areaDataWithRestaurant = { ...areaData, restaurant: restaurantId };

  try {
    const response = await api.post(url, areaDataWithRestaurant, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating area:', error);
    throw error;
  }
};

// Function to update an existing restaurant area
export const updateArea = async (areaId, updatedAreaData, token) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/`;

  try {
    const response = await api.put(url, updatedAreaData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating area:', error);
    throw error;
  }
};

// Function to delete an existing restaurant area
export const deleteArea = async (areaId, token) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/`;

  try {
    const response = await api.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting area:', error);
    throw error;
  }
};

// Function to fetch tables for a specific area, including QR code data
export const fetchTablesForArea = async (areaId, token) => {
  const response = await api.get(`${API_BASE_URL}api/tables/?area=${areaId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

// Function to fetch tables for a specific restaurant, including QR code data
export const fetchAllTablesForRestaurant = async (restaurantId, token) => {
  const response = await api.get(`${API_BASE_URL}api/tables/?restaurant_id=${restaurantId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data; // Include unique identifiers for each table
};

// Function to create a new table for a given area
export const addTableToArea = async (areaId, token) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/add-table/`;

  try {
    const response = await api.post(url, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.table;
  } catch (error) {
    console.error('Error adding table to area:', error);
    throw error;
  }
};

// Function to create multiple tables within an area
export const createMultipleTables = async (tables, areaId, token) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/create-tables/`;
  try {
    const response = await api.post(url, { tables }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating multiple tables:', error);
    throw error;
  }
};

// Function to update an existing table
export const updateTable = async (tableId, updatedTableData, token) => {
  const url = `${API_BASE_URL}api/tables/${tableId}/`;

  try {
    const response = await api.put(url, updatedTableData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating table:', error);
    throw error;
  }
};

// Function to update multiple tables' positions in bulk
export const updateTablePositions = async (tables, token) => {
    const url = `${API_BASE_URL}api/tables/update-positions/`;
    const payload = {
        tables: tables.map(table => ({
            id: table.id,
            position_x: table.position_x,
            position_y: table.position_y
        }))
    };

    try {
        const response = await api.post(url, payload, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating table positions:', error.response?.data);
        throw error;
    }
};

// Function to delete a table
export const deleteTable = async (tableId, token) => {
  const url = `${API_BASE_URL}api/tables/${tableId}/`;

  try {
    await api.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    console.error('Error deleting table:', error);
    throw error;
  }
};

// Function to create a new menu
export const createMenu = async (newMenuData, token) => {
  const url = `${API_BASE_URL}api/menu/`;

  try {
    const response = await api.post(url, newMenuData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating menu:', error);
    throw error;
  }
};

// Function to update an existing menu with area and active timeframe
export const updateMenu = async (menuId, updatedMenuData, areaId, token) => {
  const url = `${API_BASE_URL}api/menu/${menuId}/`;
  if (areaId) updatedMenuData.area = areaId; // Update area association if provided

  try {
    const response = await api.patch(url, updatedMenuData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating menu:', error.response ? error.response.data : error);
    throw error;
  }
};

// Function to delete or dissociate a menu based on its categories and items/combos associations
export const deleteOrDissociateMenu = async (menuId, token) => {
  try {
    const response = await api.delete(`${API_BASE_URL}api/menu/${menuId}/delete-or-dissociate/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data; // This will include the action taken ('deleted' or 'dissociated')
  } catch (error) {
    console.error('Error deleting or dissociating menu:', error);
    throw error;
  }
};

// Function to create and update a menu category
export const handleMenuCategory = async (categoryData, token, categoryId = null) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  try {
    let response;

    if (categoryId) {
      // If updating an existing category
      response = await api.patch(`${API_BASE_URL}api/menu-categories/${categoryId}/`, categoryData, { headers });
    } else {
      // For creating a new category, ensuring categoryData.menus is handled
      response = await api.post(`${API_BASE_URL}api/menu-categories/`, categoryData, { headers });
    }

    return response.data;
  } catch (error) {
    console.error(`Error ${categoryId ? 'updating' : 'creating'} category:`, error);
    throw error;
  }
};

// Function to get category details
export const getCategoryDetails = async (categoryId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/menu-categories/${categoryId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching category details:', error);
    throw error;
  }
};

// Function to fetch menu categories based on menu
export const fetchMenuCategories = async (menuId) => {
  const url = `${API_BASE_URL}api/menu-categories/?menu_id=${menuId}`;
  const token = localStorage.getItem('access_token');
  
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu categories:', error);
    throw error;
  }
};

// Function to delete a menu category
export const deleteMenuCategory = async (categoryId, token) => {
  const url = `${API_BASE_URL}api/menu-categories/${categoryId}/`;

  try {
    await api.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    console.error('Error deleting menu category:', error);
    throw error;
  }
};

// Function to delete or dissociate a menu category based on its associations
export const deleteOrDissociateCategory = async (categoryId, currentMenuId, token) => {
  try {
    const response = await api.delete(`${API_BASE_URL}api/menu-categories/${categoryId}/delete-or-dissociate/`, {
      headers: { Authorization: `Bearer ${token}` },
      data: { current_menu_id: currentMenuId }, // Include current menu ID in the request body for dissociation logic
    });
    return response.data; // This will include the action taken ('deleted' or 'dissociated')
  } catch (error) {
    console.error('Error deleting or dissociating menu category:', error);
    throw error;
  }
};

// Function to update the category with the new menu
export const updateCategoryMenus = async (categoryId, categoryData, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  try {
    const response = await api.patch(`${API_BASE_URL}api/menu-categories/${categoryId}/`, categoryData, { headers });
    return response.data;
  } catch (error) {
    console.error(`Error updating category with new menu:`, error);
    throw error;
  }
};

// Function to fetch details for a specific menu item, including its associated categories
export const fetchMenuItemDetails = async (itemId, token) => {
  try {
    const url = `${API_BASE_URL}api/menu-items/${itemId}/get_item_by_id/`;

    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data; // This should include the menu item details along with associated categories
  } catch (error) {
    console.error(`Error fetching details for menu item ${itemId}:`, error);
    throw error;
  }
};

// Function to create and update a menu item
export const handleMenuItem = async (menuItemData, token, itemId = null) => {
  let url = `${API_BASE_URL}api/menu-items/`;

  try {
    let response;
    if (itemId) {
      // Update existing menu item
      url += `${itemId}/`;
      response = await api.patch(url, menuItemData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      // Create new menu item
      response = await api.post(url, menuItemData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
    }
    return response.data;
  } catch (error) {
    console.error(`Error ${itemId ? 'updating' : 'creating'} menu item:`, error);
    throw error;
  }
};

// Function to delete or dissociate a menu item based on its associations
export const deleteOrDissociateMenuItem = async (itemId, currentCategoryId, token) => {
  const url = `${API_BASE_URL}api/menu-items/${itemId}/delete-or-dissociate/`;

  try {
    const response = await api.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        current_category_id: currentCategoryId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting or dissociating menu item:', error);
    throw error;
  }
};

// Function to update the menu item with new categories
export const updateMenuItemCategories = async (itemId, updatedCategoryIds, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  try {
    const response = await api.patch(`${API_BASE_URL}api/menu-items/${itemId}/update_categories/`, 
      { categories: updatedCategoryIds }, 
      { headers });
    return response.data;
  } catch (error) {
    console.error(`Error updating menu item with new categories:`, error);
    throw error;
  }
};

// Function to update the combo with new categories
export const updateComboCategories = async (comboId, updatedCategoryIds, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  try {
    const response = await api.patch(`${API_BASE_URL}api/combos/${comboId}/update_combo_categories/`, 
      { categories: updatedCategoryIds }, 
      { headers });
    return response.data;
  } catch (error) {
    console.error(`Error updating combo with new categories:`, error);
    throw error;
  }
};

// Function to fetch allergens
export const fetchAllergens = async (token) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/allergens/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching allergens:', error);
    throw error;
  }
};

// Function to fetch diet groups
export const fetchDietGroups = async (token) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/diet-groups/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching diet groups:', error);
    throw error;
  }
};

// Function to update the order of menu categories and items
export const updateMenuOrder = async (orderData, token) => {
  const url = `${API_BASE_URL}api/update-menu-order/`;

  try {
    const response = await api.post(url, orderData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating menu order:', error);
    throw error;
  }
};

// Function to assign menus to restaurant's areas
export const assignMenuToRestaurantAndArea = async (selectedMenu, areaIds, token) => {
  const promises = areaIds.map(async (areaId) => {
    const url = `${API_BASE_URL}api/areas/${areaId}/add_menu/`; // URL pattern defined for adding a menu to an area
    const data = { menu_id: selectedMenu };
    return api.post(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  });

  try {
    // Await all promises and return a success response if all succeed
    await Promise.all(promises);
    return { status: 200 };
  } catch (error) {
    console.error('Error assigning menu to areas:', error);
    throw error;
  }
};

// Function to fetch combos by category
export const fetchCombosByCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/combos/?category_id=${categoryId}`;
  const token = localStorage.getItem('access_token');
  
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items by category:', error);
    throw error;
  }
};

export const fetchCombosForRestaurant = async (restaurantId, token, selectedCategoryId = null) => {
  let url = `${API_BASE_URL}api/combos/?restaurant_id=${restaurantId}`;

  if (selectedCategoryId !== null) {
    url += `&category_id=${selectedCategoryId}`;
  }

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching combos:', error);
    throw error;
  }
};

// Function to fetch combos available for association
export const fetchCombosAvailableForAssociation = async (selectedMenu, selectedCategory, token) => {
  let url = `${API_BASE_URL}api/combos/available_for_association/?selectedMenu=${selectedMenu}`;

  if (selectedCategory) {
    url += `&selectedCategory=${selectedCategory}`;
  }

  try {
    const response = await api.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching combos available for association:', error);
    throw error;
  }
};

export const createCombo = async (data, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
  };

  // Do not manually set Content-Type for FormData
  return api.post(`${API_BASE_URL}api/combos/`, data, { headers });
};

export const updateCombo = async (id, data, token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
  };

  // Do not manually set Content-Type for FormData
  return api.patch(`${API_BASE_URL}api/combos/${id}/`, data, { headers });
};

export const deleteCombo = async (id, token) => {
  return api.delete(`${API_BASE_URL}api/combos/${id}/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

// Function to delete or dissociate a combo based on its associations
export const deleteOrDissociateCombo = async (comboId, currentCategoryId, token) => {
  const url = `${API_BASE_URL}api/combos/${comboId}/delete-or-dissociate/`;

  try {
    const response = await api.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
      data: {
        current_category_id: currentCategoryId,
      }, 
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting or dissociating combo:', error);
    throw error;
  }
};

// VersionGroup-related functions
export const createVersionGroup = async (data, token) => {
  return api.post(`${API_BASE_URL}api/version-groups/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const fetchVersionGroups = async (token) => {
  return api.get(`${API_BASE_URL}api/version-groups/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const updateVersionGroup = async (id, data, token) => {
  return api.patch(`${API_BASE_URL}api/version-groups/${id}/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const deleteVersionGroup = async (id, token) => {
  return api.delete(`${API_BASE_URL}api/version-groups/${id}/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

// Version-related functions
export const createVersion = async (data, token) => {
  return api.post(`${API_BASE_URL}api/versions/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const fetchVersions = async (token) => {
  return api.get(`${API_BASE_URL}api/versions/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const updateVersion = async (id, data, token) => {
  return api.patch(`${API_BASE_URL}api/versions/${id}/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const deleteVersion = async (id, token) => {
  return api.delete(`${API_BASE_URL}api/versions/${id}/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

// ExtrasLists-related functions
export const createExtrasList = async (data, token) => {
  return api.post(`${API_BASE_URL}api/extras-lists/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const fetchExtrasLists = async (token) => {
  return api.get(`${API_BASE_URL}api/extras-lists/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const updateExtrasList = async (id, data, token) => {
  return api.patch(`${API_BASE_URL}api/extras-lists/${id}/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const deleteExtrasList = async (id, token) => {
  return api.delete(`${API_BASE_URL}api/extras-lists/${id}/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

// Extras-related functions
export const createExtra = async (data, token) => {
  return api.post(`${API_BASE_URL}api/extras/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const fetchExtras = async (token) => {
  return api.get(`${API_BASE_URL}api/extras/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const updateExtra = async (id, data, token) => {
  return api.patch(`${API_BASE_URL}api/extras/${id}/`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const deleteExtra = async (id, token) => {
  return api.delete(`${API_BASE_URL}api/extras/${id}/`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

// Function to fetch Staff roles
export const fetchRoles = async (token) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/roles/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

// Function to add a staff member
export const addStaffMember = async (staffData, token) => {
  const url = `${API_BASE_URL}api/add_staff/`;
  try {
    const response = await api.post(url, staffData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding staff member:', error);
    throw error;
  }
};

// Function to fetch all staff members
export const fetchStaffMembers = async (token) => {
  const url = `${API_BASE_URL}api/staff/`;
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching staff members:', error);
    throw error;
  }
};

// Function to udpate staff members
export const updateStaffMember = async (id, data, token) => {
  const url = `${API_BASE_URL}api/staff/${id}/`;
  try {
    const response = await api.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating staff member:', error);
    throw error;
  }
};

// Function to delete a staff member
export const deleteStaffMember = async (id, token) => {
  const url = `${API_BASE_URL}api/staff/${id}/`;
  try {
    const response = await api.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.status === 204;  // Return true if deletion was successful
  } catch (error) {
    console.error('Error deleting staff member:', error);
    throw error;
  }
};

// Function to fetch all orders for the restaurant
export const fetchOrdersForRestaurant = async (restaurantId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/orders/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { restaurant_id: restaurantId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all orders:', error);
    throw error;
  }
};

// Other configurations
api.defaults.withCredentials = true;
api.defaults.xsrfCookieName = 'csrftoken';
api.defaults.xsrfHeaderName = 'X-CSRFToken';
