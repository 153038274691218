import React, { useState } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import styled from 'styled-components';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { NavigateNext, MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, IconButton, Typography } from '@mui/material';
import { UnderlinedButton } from '../../Common/MUI.StyledComponents';

// Styled components
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  user-select: none;
`;

const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-left: 5%;
`;

const IconColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  color: grey;
  cursor: pointer;
`;

const CategoryTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  font-size: 17px;
  color: var(--color-black);
  border: 1px solid var(--color-lightgrey);
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: grab;
`;

const CategoryDescriptionTab = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  align-items: center;
  justify-content: left;
  color: var(--color-grey);
  font-size: 0.85rem;
`;

const MenuItemTab = styled.div`
  display: flex;
  ${({ $isExpanded }) => $isExpanded ? 'flex-direction: column;' : 'flex-direction: row;'}
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  font-size: 15px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  transition: height 0.3s ease-in-out;
  ${({ $isExpanded }) => $isExpanded && `
    height: auto;
  `}
`;

const ItemName = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const PriceDisplay = styled.span`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const MenuItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const TextDetails = styled.div`
  flex: 1;
  margin-right: 10px;
  font-style: italic;
`;

const PictureContainer = styled.div`
  width: 100px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  position: relative;
  border-radius: 10px;
  border: 1px solid darkgrey;

  img {
    width: auto;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid darkgrey;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ItemPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px dashed lightgrey;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  font-size: 16px;
  color: #3e3effe3;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 15px;
  &:hover {
    background-color: #e9ecef;
    border-style: solid;
  }
`;

// Utility functions
const renderToggleIcon = (isExpanded) => (
  <IconButton>
    <NavigateNext
      style={{
        transition: 'transform 0.3s ease-in-out',
        transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
      }}
    />
  </IconButton>
);

// Main component
const CategoryList = ({
  expandedCategories,
  expandedItems,
  toggleCategory,
  toggleItem,
  handleAddCategorySelection,
  handleAddMenuItemSelection,
  openEditCategorySidebar,
  openEditMenuItemSidebar,
  openEditComboSidebar,
  openDeleteConfirmation,
  setCategoryActionSelection,
  categoryActionSelection,
  setMenuItemActionSelection,
  menuItemActionSelection,
  addCategoryOptions,
  addMenuItemOptions,
}) => {
  // Shared states
  const {
    menuCategories, menuItems, combos, selectedMenu, allergens, dietGroups, selectedCategory, setSelectedCategory
  } = useRestaurant();

  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const openAddMenu = Boolean(anchorElAdd);
  const openEditMenu = Boolean(anchorElEdit);

  const handleAddButtonClick = (event, categoryId) => {
    setSelectedCategory(categoryId)
    setAnchorElAdd(event.currentTarget);
  };

  const handleAddMenuClose = () => {
    setAnchorElAdd(null);
  };

  const handleMenuItemClick = (option) => {
    if (selectedCategory) {  // Ensure selectedCategory is set
      handleAddMenuItemSelection(option, selectedCategory); // Pass the selected category to parent
    }
    handleAddMenuClose();
  };

  const handleMoreVertClick = (event, item) => {
    setAnchorElEdit(event.currentTarget);
    setCurrentItem(item);
  };

  const handleEditMenuClose = () => {
    setAnchorElEdit(null);
    setCurrentItem(null);
  };

  const handleEditClick = () => {
    if (currentItem.type === 'category') {
      openEditCategorySidebar(currentItem.data);
    } else if (currentItem.type === 'menuItem') {
      openEditMenuItemSidebar(currentItem.data);
    } else if (currentItem.type === 'combo') {
      openEditComboSidebar(currentItem.data);
    }
    handleEditMenuClose();
  };

  const handleDeleteClick = () => {
    openDeleteConfirmation(currentItem.data.id, currentItem.type, selectedMenu, currentItem.categoryId);
    handleEditMenuClose();
  };

  const renderItemAllergensAndDietGroups = (items) => {
    if (!items) {
      return <div />;
    }

    // Extract allergen names directly from the combo
    const itemAllergenNames = items.allergens.map(allergenId => {
      const allergen = allergens.find(a => a.id === allergenId); // Consumed from context
      return allergen ? allergen.name : 'Unknown Allergen';
    });

    // Extract diet group names directly from the combo
    const itemDietGroupNames = items.diet_groups.map(dietGroupId => {
      const dietGroup = dietGroups.find(d => d.id === dietGroupId); // Consumed from context
      return dietGroup ? dietGroup.name : 'Unknown Diet Group';
    });

    return (
      <div>
        <p>Allergens: {itemAllergenNames.length ? itemAllergenNames.join(', ') : 'None'}</p>
        <p>Diet Groups: {itemDietGroupNames.length ? itemDietGroupNames.join(', ') : 'None'}</p>
      </div>
    );
  };

  return (
    <ListContainer>
      <Droppable droppableId="droppableCategories" type="CATEGORY">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {menuCategories.map((category, index) => (
              <Draggable key={category.id} draggableId={`category-${category.id}`} index={index}>
                {(provided) => (
                  <ListContainer ref={provided.innerRef} {...provided.draggableProps}>
                    <CategoryContainer>
                      <IconColumn onClick={() => toggleCategory(category.id)}>
                        {renderToggleIcon(expandedCategories[category.id])}
                      </IconColumn>
                      <TabsContainer>
                        <CategoryTab {...provided.dragHandleProps}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontWeight: 'bold' }}>
                            {category.name}
                            <IconButton onClick={(event) => handleMoreVertClick(event, { type: 'category', data: category })}>
                              <MoreVert />
                            </IconButton>
                          </div>
                          <CategoryDescriptionTab>
                            {category.description || 'No description provided'}
                          </CategoryDescriptionTab>
                        </CategoryTab>
                      </TabsContainer>
                    </CategoryContainer>
                    {expandedCategories[category.id] && (
                      <>
                        <Droppable droppableId={`droppableItems-${category.id}`} type="ITEM">
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {menuItems
                                .filter(item => item.categories.includes(category.id))
                                .map((item, itemIndex) => (
                                  <Draggable key={item.id} draggableId={`item-${item.id}`} index={itemIndex}>
                                    {(provided) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps}>
                                        <ItemContainer>
                                          <IconColumn onClick={() => toggleItem(item.id)}>
                                            {renderToggleIcon(expandedItems[item.id])}
                                          </IconColumn>
                                          <TabsContainer>
                                            <MenuItemTab $isExpanded={expandedItems[item.id]} {...provided.dragHandleProps}>
                                              <MenuItemDetails>
                                                <ItemName>{item.name}</ItemName>
                                                <PriceDisplay>€{item.price}</PriceDisplay>
                                                <IconButton onClick={(event) => handleMoreVertClick(event, { type: 'menuItem', data: item, categoryId: category.id })}>
                                                  <MoreVert />
                                                  </IconButton>
                                              </MenuItemDetails>
                                              {expandedItems[item.id] && (
                                                <MenuItemDetails>
                                                  <TextDetails>
                                                    <p>{item.description}</p>
                                                    {renderItemAllergensAndDietGroups(item)}
                                                  </TextDetails>
                                                  <PictureContainer>
                                                    {item.picture && <img src={item.picture} alt={item.name} />}
                                                  </PictureContainer>
                                                </MenuItemDetails>
                                              )}
                                            </MenuItemTab>
                                          </TabsContainer>
                                        </ItemContainer>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}

                              <Droppable droppableId={`droppableCombos-${category.id}`} type="COMBOS">
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {combos
                                      .filter(combo => combo.categories.includes(category.id))
                                      .map((combo, comboIndex) => (
                                        <Draggable key={combo.id} draggableId={`combo-${combo.id}`} index={comboIndex}>
                                          {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                              <ItemContainer {...provided.dragHandleProps}>
                                                <IconColumn onClick={() => toggleItem(combo.id)}>
                                                  {renderToggleIcon(expandedItems[combo.id])}
                                                </IconColumn>
                                                <TabsContainer>
                                                  <MenuItemTab $isExpanded={expandedItems[combo.id]}>
                                                    <MenuItemDetails>
                                                      <ItemName>{combo.name}</ItemName>
                                                      <PriceDisplay>€{combo.price}</PriceDisplay>
                                                      <IconButton onClick={(event) => handleMoreVertClick(event, { type: 'combo', data: combo, categoryId: category.id })}>
                                                        <MoreVert />
                                                      </IconButton>
                                                    </MenuItemDetails>
                                                    {expandedItems[combo.id] && (
                                                      <MenuItemDetails>
                                                        <TextDetails>
                                                          <Typography color="secondary">Items: {combo.item_names}</Typography>
                                                          <p>{combo.description}</p>
                                                          {renderItemAllergensAndDietGroups(combo)}
                                                        </TextDetails>
                                                        <PictureContainer>
                                                          {combo.picture && <img src={combo.picture} alt={combo.name} />}
                                                        </PictureContainer>
                                                      </MenuItemDetails>
                                                    )}
                                                  </MenuItemTab>
                                                </TabsContainer>
                                              </ItemContainer>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>

                              {provided.placeholder}
                              <ItemContainer>
                                <IconColumn />
                                <TabsContainer>
                                  <ItemPlaceholder>
                                    <UnderlinedButton onClick={(event) => handleAddButtonClick(event, category.id)} open={openAddMenu}>
                                      Add a dish, drink or combo
                                    </UnderlinedButton>
                                    <Menu
                                      anchorEl={anchorElAdd}
                                      open={openAddMenu}
                                      onClose={handleAddMenuClose}
                                    >
                                      {addMenuItemOptions.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          onClick={() => handleMenuItemClick(option)}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Menu>
                                  </ItemPlaceholder>
                                </TabsContainer>
                              </ItemContainer>
                            </div>
                          )}
                        </Droppable>
                      </>
                    )}
                  </ListContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Menu
        anchorEl={anchorElEdit}
        open={openEditMenu}
        onClose={handleEditMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </ListContainer>
  );
};

export default CategoryList;
