import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { updateCombo, createCombo, fetchAllergens, fetchDietGroups } from '../../../services/apiService';
import { TextField, Button, Typography, Box, FormControl, InputLabel, MenuItem, Select, FormControlLabel, RadioGroup, Radio, ListSubheader, Checkbox, Alert } from '@mui/material';
import PictureUpload from '../../Common/PictureUpload';

const ComboForm = ({ context, onClose }) => {
  const [comboName, setComboName] = useState('');
  const [comboDescription, setComboDescription] = useState('');
  const [selectedComboOptions, setSelectedComboOptions] = useState([]);
  const [fixedPrice, setFixedPrice] = useState(0);
  const [useFixedPrice, setUseFixedPrice] = useState(false);
  const [priceModifier, setPriceModifier] = useState(0);
  const [selectedAllergens, setSelectedAllergens] = useState([]);
  const [selectedDietGroups, setSelectedDietGroups] = useState([]);
  const [dietGroupOptions, setDietGroupOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [existingFile, setExistingFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [clearExistingFile, setClearExistingFile] = useState(false);

  const { showSidebar, editingCombo, addNewCombo, updateCombos, selectedMenu, selectedCategory, menuCategories, menuItems } = useRestaurant();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const loadOptions = async () => {
      try {
        const [dietGroups] = await Promise.all([
          fetchAllergens(token),
          fetchDietGroups(token)
        ]);
        setDietGroupOptions(dietGroups.map(dietGroup => ({ value: dietGroup.id, label: dietGroup.name })));
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    loadOptions();
  }, []);

  useEffect(() => {
    if (context === 'editCombo' && editingCombo) {
      setComboName(editingCombo.name);
      setComboDescription(editingCombo.description);
      setSelectedComboOptions(editingCombo.items.map(itemId => itemId));
      setFixedPrice(editingCombo.fixed_price || 0);
      setUseFixedPrice(editingCombo.use_fixed_price);
      setPriceModifier(editingCombo.price_modifier || 0);
      setSelectedAllergens(editingCombo.allergens);
      setSelectedDietGroups(editingCombo.diet_groups);
      setExistingFile(editingCombo.picture);
      setPreviewUrl(editingCombo.picture);
      setClearExistingFile(false);
    } else {
      resetForm();
    }
  }, [context, editingCombo, showSidebar]);

  const resetForm = () => {
    setComboName('');
    setComboDescription('');
    setSelectedComboOptions([]);
    setFixedPrice(0);
    setUseFixedPrice(false);
    setPriceModifier(0);
    setSelectedAllergens([]);
    setSelectedDietGroups([]);
    setSelectedFile(null);
    setPreviewUrl(null);
    setExistingFile(null);
    setClearExistingFile(false);
  };

  const handleCreateOrUpdateCombo = async (event) => {
    event.preventDefault();

    if (!comboName.trim()) {
      console.error("Combo name cannot be empty");
      return;
    }

    try {
      const token = localStorage.getItem('access_token');

      const comboData = new FormData();
      comboData.append('name', comboName);
      comboData.append('description', comboDescription);
      comboData.append('menus', selectedMenu);
      selectedCategory.forEach(categoryId => {
        comboData.append('categories', categoryId);
      });
      selectedComboOptions.forEach(itemId => {
        comboData.append('items', itemId);
      });
      comboData.append('use_fixed_price', useFixedPrice);
      selectedAllergens.forEach(allergen => comboData.append('allergens', allergen));
      selectedDietGroups.forEach(dietGroup => comboData.append('diet_groups', dietGroup));

      if (selectedFile) {
        comboData.append('picture', selectedFile);
      } else if (clearExistingFile) {
        comboData.append('picture', ''); // Signal to clear the existing picture
      }

      if (useFixedPrice) {
        comboData.append('fixed_price', fixedPrice);
      } else {
        comboData.append('price_modifier', priceModifier);
      }

      let response;

      if (context === 'editCombo' && editingCombo) {
        response = await updateCombo(editingCombo.id, comboData, token);
        updateCombos(response);
      } else {
        response = await createCombo(comboData, token);
        addNewCombo(response);
      }

      resetForm();
      onClose();
    } catch (error) {
      console.error('Error handling combo:', error);
    }
  };

  const handleComboSelectionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedComboOptions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleFileSelect = (file, previewUrl) => {
    setSelectedFile(file);
    setPreviewUrl(previewUrl);
    setClearExistingFile(false);
  };

  const handleClearImage = () => {
    setExistingFile(null);
    setSelectedFile(null);
    setPreviewUrl(null);
    setClearExistingFile(true);
  };

  const handleDietGroupChange = (event) => {
    const { value } = event.target;
    setSelectedDietGroups(value);
  };

  const getGroupedOptions = () => {
    return menuCategories.map(category => ({
      label: category.name,
      options: menuItems
        .filter(item => item.categories.includes(category.id))
        .map(item => ({
          label: item.name,
          value: item.id,
        })),
    }));
  };

  const handleRadioChange = (e) => {
    setUseFixedPrice(e.target.value === 'custom');
  };

  return (
    <Box component="form" onSubmit={handleCreateOrUpdateCombo}>
      <Typography variant="h6">{context === 'editCombo' ? 'Edit Combo' : 'Add a New Combo'}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Combo Name"
        placeholder="e.g. Burger Menu"
        value={comboName}
        onChange={(e) => setComboName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Description"
        placeholder="e.g. Burger plus fries"
        value={comboDescription}
        onChange={(e) => setComboDescription(e.target.value)}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Select items</InputLabel>
        <Select
          multiple
          value={selectedComboOptions}
          onChange={handleComboSelectionChange}
          label="Select items"
          renderValue={(selected) => selected.map((id) => {
            const item = menuItems.find((item) => item.id === id);
            return item ? item.name : '';
          }).join(', ')}
        >
          {getGroupedOptions().map(group => [
            <ListSubheader key={group.label}>{group.label}</ListSubheader>,
            group.options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          ])}
        </Select>
      </FormControl>
      <FormControl component="fieldset">
        <RadioGroup
          row
          value={useFixedPrice ? 'custom' : 'default'}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="default"
            control={<Radio color="primary" />}
            label="Use items price"
          />
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label="Custom price"
          />
        </RadioGroup>
      </FormControl>
        {useFixedPrice ? (
          <TextField
            fullWidth
            variant="outlined"
            label="Price (€)"
            type="number"
            value={fixedPrice}
            onChange={(e) => setFixedPrice(parseFloat(e.target.value))}
            margin="normal"
          />
        ) : (
        <TextField
          fullWidth
          variant="outlined"
          label="Discount Rate (%)"
          type="number"
          value={priceModifier}
          onChange={(e) => setPriceModifier(parseFloat(e.target.value))}
          margin="normal"
          inputProps={{ min: 0, max: 100 }}
        />
      )}
      <Alert severity="warning">
        Please ensure allergens are defined for each combo components.
      </Alert>
      <FormControl fullWidth margin="normal">
        <InputLabel>Diet Groups</InputLabel>
        <Select
          multiple
          value={selectedDietGroups}
          onChange={handleDietGroupChange}
          renderValue={(selected) => selected.map((item) => dietGroupOptions.find(option => option.value === item)?.label).join(', ')}
          label="Diet Groups"
        >
          {dietGroupOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <FormControlLabel
                control={<Checkbox size="small" checked={selectedDietGroups.indexOf(option.value) > -1} />}
                label={option.label}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <PictureUpload
        existingFile={existingFile}
        previewUrl={previewUrl}
        onFileSelect={handleFileSelect}
        onClearImage={handleClearImage}
      />
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="contained" color="primary" type="submit">
        {context === 'editCombo' ? 'Update Combo' : 'Add Combo'}
      </Button>
    </div>
    </Box>
  );
};

export default ComboForm;
