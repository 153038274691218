import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import './App.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/MUI.theme';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { RestaurantProvider } from './contexts/RestaurantContext';
import { POSProvider } from './contexts/POSContext';
import { BrowserRouter as Router } from 'react-router-dom';

// Configure Axios for CSRF tokens
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <RestaurantProvider>
            <POSProvider>
              <App />
            </POSProvider>
          </RestaurantProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();