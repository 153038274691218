import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { handleMenuItem, fetchAllergens, fetchDietGroups } from '../../../services/apiService';
import { TextField, Button, Typography, Box, FormControl, InputLabel, MenuItem, Select, FormControlLabel, Checkbox, Switch } from '@mui/material';
import PictureUpload from '../../Common/PictureUpload';
import ComboForm from './ComboForm';

const MenuItemForm = ({ context, onClose }) => {
  const [newMenuItemName, setNewMenuItemName] = useState('');
  const [newMenuItemDescription, setNewMenuItemDescription] = useState('');
  const [newMenuItemPrice, setNewMenuItemPrice] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [existingFile, setExistingFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [clearExistingFile, setClearExistingFile] = useState(false);
  const [selectedAllergens, setSelectedAllergens] = useState([]);
  const [selectedDietGroups, setSelectedDietGroups] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const [dietGroupOptions, setDietGroupOptions] = useState([]);
  const [isCombo, setIsCombo] = useState(false); // New state for the switch
  const { showSidebar, editingMenuItem, addNewMenuItem, updateMenuItems, selectedCategory } = useRestaurant();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const loadOptions = async () => {
      try {
        const [allergens, dietGroups] = await Promise.all([
          fetchAllergens(token),
          fetchDietGroups(token)
        ]);
        setAllergenOptions(allergens.map(allergen => ({ value: allergen.id, label: allergen.name })));
        setDietGroupOptions(dietGroups.map(dietGroup => ({ value: dietGroup.id, label: dietGroup.name })));
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    loadOptions();
  }, []);

  useEffect(() => {
    if (context === 'editMenuItem' && editingMenuItem) {
      setNewMenuItemName(editingMenuItem.name);
      setNewMenuItemDescription(editingMenuItem.description);
      setNewMenuItemPrice(editingMenuItem.price);
      setSelectedAllergens(editingMenuItem.allergens);
      setSelectedDietGroups(editingMenuItem.diet_groups);
      setExistingFile(editingMenuItem.picture);
      setPreviewUrl(editingMenuItem.picture); // Set the existing picture as preview
      setClearExistingFile(false);
    } else {
      resetForm();
    }
  }, [context, editingMenuItem, showSidebar]);

  const resetForm = () => {
    setNewMenuItemName('');
    setNewMenuItemDescription('');
    setNewMenuItemPrice('');
    setSelectedFile(null);
    setPreviewUrl(null);
    setSelectedAllergens([]);
    setSelectedDietGroups([]);
    setExistingFile(null);
    setClearExistingFile(false);
  };

  const handleCreateOrUpdateMenuItem = async (event) => {
    event.preventDefault();
    if (!newMenuItemName.trim() || !selectedCategory) {
      console.error("Item name and category must be selected");
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      const menuItemData = new FormData();
      menuItemData.append('name', newMenuItemName);
      menuItemData.append('description', newMenuItemDescription);
      menuItemData.append('price', newMenuItemPrice);

      selectedCategory.forEach(categoryId => {
        menuItemData.append('categories', categoryId);
      });

      if (selectedFile) {
        menuItemData.append('picture', selectedFile);
      } else if (clearExistingFile) {
        menuItemData.append('picture', ''); // Signal to clear the existing picture
      }

      if (selectedAllergens.length > 0) {
        selectedAllergens.forEach(allergen => {
          menuItemData.append('allergens', allergen);
        });
      }

      if (selectedDietGroups.length > 0) {
        selectedDietGroups.forEach(dietGroup => {
          menuItemData.append('diet_groups', dietGroup);
        });
      }

      let response;

      if (context === 'editMenuItem' && editingMenuItem) {
        response = await handleMenuItem(menuItemData, token, editingMenuItem.id);
        updateMenuItems(response);
      } else {
        response = await handleMenuItem(menuItemData, token);
        addNewMenuItem(response);
      }

      resetForm();
      onClose();
    } catch (error) {
      console.error('Error handling menu item:', error);
    }
  };

  const handleFileSelect = (file, previewUrl) => {
    setSelectedFile(file);
    setPreviewUrl(previewUrl); // Set the preview URL for the selected file
    setClearExistingFile(false);
  };

  const handleClearImage = () => {
    setExistingFile(null);
    setSelectedFile(null);
    setPreviewUrl(null); // Clear the preview URL
    setClearExistingFile(true);
  };

  const handleAllergenChange = (event) => {
    const { value } = event.target;
    setSelectedAllergens(value);
  };

  const handleDietGroupChange = (event) => {
    const { value } = event.target;
    setSelectedDietGroups(value);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isCombo}
            onChange={(e) => setIsCombo(e.target.checked)}
            name="isCombo"
            color="primary"
          />
        }
        label="Make Combo"
      />
      {isCombo ? (
        <ComboForm context={context} onClose={onClose} />
      ) : (
        <Box component="form" onSubmit={handleCreateOrUpdateMenuItem}>
          <Typography variant="h6">{context === 'editMenuItem' ? 'Edit Menu Item' : 'Add a New Menu Item'}</Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            placeholder="e.g. Cheeseburger, Lasagna, etc"
            value={newMenuItemName}
            onChange={(e) => setNewMenuItemName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Description"
            placeholder="e.g. Burger with cheese"
            value={newMenuItemDescription}
            onChange={(e) => setNewMenuItemDescription(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Price (€)"
            type="number"
            value={newMenuItemPrice}
            onChange={(e) => setNewMenuItemPrice(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Allergens</InputLabel>
            <Select
              multiple
              value={selectedAllergens}
              onChange={handleAllergenChange}
              renderValue={(selected) => selected.map((item) => allergenOptions.find(option => option.value === item)?.label).join(', ')}
              label="Allergens"
            >
              {allergenOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  <FormControlLabel
                    control={<Checkbox size="small" checked={selectedAllergens.indexOf(option.value) > -1} />}
                    label={option.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Diet Groups</InputLabel>
            <Select
              multiple
              value={selectedDietGroups}
              onChange={handleDietGroupChange}
              renderValue={(selected) => selected.map((item) => dietGroupOptions.find(option => option.value === item)?.label).join(', ')}
              label="Diet Groups"
            >
              {dietGroupOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  <FormControlLabel
                    control={<Checkbox size="small" checked={selectedDietGroups.indexOf(option.value) > -1} />}
                    label={option.label}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <PictureUpload
            existingFile={existingFile}
            previewUrl={previewUrl}
            onFileSelect={handleFileSelect}
            onClearImage={handleClearImage}
          />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" type="submit" margin="normal">
            {context === 'editMenuItem' ? 'Update Menu Item' : 'Add Menu Item'}
          </Button>
        </div>
        </Box>
      )}
    </>
  );
};

export default MenuItemForm;
