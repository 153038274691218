// RestaurantHeader.jsx
import React from 'react';
import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem, Button } from '@mui/material';
import { CustomSelect, ClickLabel } from '../../Common/MUI.StyledComponents';
import { useRestaurant } from '../../../contexts/RestaurantContext';

const HeaderContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
`;

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  padding-left: 20px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const RestaurantHeader = ({
  restaurantOptions,
  handleRestaurantChange,
  selectedRestaurant,
  setShowSetupWizard,
  openSidebarForAreaCreation,
  openSidebarForEditing,
  toggleDeleteModal,
  handleToggleDefault
}) => {
  const { setSelectedRestaurant } = useRestaurant();

  return (
    <HeaderContainerStyled>
      <ToggleButtonsContainer>
        <FormControl variant="outlined" style={{ width: 300 }}>
          <InputLabel id="restaurant-select-label">Select Restaurant</InputLabel>
          <CustomSelect
            labelId="restaurant-select-label"
            value={selectedRestaurant || ''}
            onChange={(event) => {
              setSelectedRestaurant(event.target.value);
              handleRestaurantChange(event.target.value);
            }}
            label="Select Restaurant"
          >
            {restaurantOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
          <ClickLabel style={{ marginRight: '15px', padding: '4px' }} onClick={() => openSidebarForEditing(selectedRestaurant)}>Edit</ClickLabel>
          <ClickLabel style={{ marginRight: '15px', padding: '4px' }} onClick={() => toggleDeleteModal(selectedRestaurant)}>Delete</ClickLabel>
          <ClickLabel style={{ padding: '4px' }} onClick={handleToggleDefault}>Set as Default</ClickLabel>
        </div>
      </ToggleButtonsContainer>

      <ActionButtonsContainer>
        <Button variant="outlined" style={{ marginRight: '15px' }} onClick={() => setShowSetupWizard(true)}>Add Restaurant</Button>
        <Button variant="contained" onClick={openSidebarForAreaCreation}>Add Area</Button>
      </ActionButtonsContainer>
    </HeaderContainerStyled>
  );
};

export default RestaurantHeader;
