import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TextField, Button, Typography, Alert } from '@mui/material';
import { resetPasswordEmail } from '../../services/apiService';

// Styled components
const Container = styled.div`
  padding: 4% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 4% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 4% 35% 2% 35%;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear any existing errors

    if (!email) {
      setError('Please enter your email address');
      return;
    }

    try {
      // Here we call the API to trigger the password reset email
      await resetPasswordEmail(email);
      // Redirect to the PasswordEmailSent component on success
      navigate('/password-email-sent');
    } catch (error) {
      // If the API indicates the email doesn't exist, set the appropriate error message
      if (error.response && error.response.status === 404) {
        setError('The email you have entered does not exist');
      } else {
        // Handle other API errors
        setError('An error occurred while trying to reset your password');
      }
    }
  };

  return (
    <Container>
      <Typography variant="h2" gutterBottom>Password reset</Typography>
      <Typography variant="body1">Please enter your email below:</Typography>
      <Form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          variant="outlined"
          error={!!error}
          helperText={error}
          autoComplete="email"
        />
        {error && <Alert severity="error" style={{ width: '100%', marginTop: '10px' }}>{error}</Alert>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Next
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordReset;
