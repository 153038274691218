import api from './apiInstance';

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchAreasForRestaurant = async (restaurantId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/areas/?restaurant_id=${restaurantId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching areas for restaurant:', error);
    throw error;
  }
};

// Function to fetch tables for a specific area
export const fetchTablesForArea = async (areaId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/tables/?area=${areaId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tables for area:', error);
    throw error;
  }
};

// Function to fetch tables for the entire restaurant
export const fetchTablesForRestaurant = async (restaurantId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/tables/?restaurant=${restaurantId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tables for restaurant:', error);
    throw error;
  }
};

export const fetchMenusForRestaurant = async (restaurantId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/menus-for-restaurant/${restaurantId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menus for restaurant:', error);
    throw error;
  }
};

export const fetchMenuCategories = async (menuId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/menu-categories/${menuId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu categories:', error);
    throw error;
  }
};

export const fetchMenuItemsAndCombosByCategory = async (categoryId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/menu-items-and-combos/${categoryId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items and combos by category:', error);
    throw error;
  }
};

export const fetchAggregatedOrder = async (tableId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/aggregated-order/${tableId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregated order:', error);
    throw error;
  }
};

export const createOrderItems = async (orderId, items, token) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/order-items/`, 
      {
        order_id: orderId,
        items: items,
      }, 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating order items:', error);
    throw error;
  }
};

export const createOrder = async (orderData, token) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/orders/`, orderData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData, token) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/orders/${orderId}/`, orderData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const updateCustomOrder = async (orderId, data, token) => {
  try {
    const payload = {
      order_id: orderId,
      updated_courses: data.updated_courses || {},
      new_items: data.new_items || [],
    };

    const response = await api.patch(`${API_BASE_URL}pos/custom-order-update/`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating custom order:', error);
    throw error;
  }
};

export const updateMealCourses = async (orderId, updatedCourses, token) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/update-meal-courses/`, {
      order_id: orderId,
      updated_courses: updatedCourses,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating meal courses:', error);
    throw error;
  }
};

export const updateOrderItemNote = async (itemId, payload, token) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/order-items/${itemId}/`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json' // Ensure the correct content type
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating order item note:', error);
    throw error;
  }
};

export const deleteOrder = async (orderId, token) => {
  try {
    const response = await api.delete(`${API_BASE_URL}pos/orders/${orderId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error deleting order:', error);
    throw error;
  }
};

export const fetchAggregatedOrdersByTable = async (token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/aggregated-orders-by-table/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregated orders by table:', error);
    throw error;
  }
};

export const fetchAggregatedOrdersByCourse = async (token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/aggregated-orders-by-course/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregated orders by course:', error);
    throw error;
  }
};

export const updateOrderItemStatus = async (itemId, action, token) => {
  let endpoint = '';
  if (action === 'in_preparation') {
    endpoint = 'prepare';
  } else if (action === 'ready') {
    endpoint = 'ready';
  } else if (action === 'consumed') {
    endpoint = 'consume';
  } else {
    throw new Error('Invalid action for updating order item status');
  }

  try {
    const response = await api.patch(`${API_BASE_URL}pos/order-items/${itemId}/${endpoint}/`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating item status to ${action}:`, error);
    throw error;
  }
};

export const splitOrder = async (splitOrderData, token) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/split-order/`, splitOrderData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error splitting order:', error);
    throw error;
  }
};

export const initiatePayment = async (orderId, paymentData, token) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/initiate-payment/${orderId}/`, paymentData, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const fetchOrderPaymentDetails = async (orderId, token) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/get-order-payment-details/${orderId}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order payment details:', error);
    throw error;
  }
};

export const fetchReceipts = async (restaurantId, token) => {
  try {
    const response = await api.get(`pos/restaurant/${restaurantId}/receipts/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching receipts:', error);
    throw error;
  }
};

export const createReceipt = async (orderId, token) => {
  try {
    const response = await api.post(`pos/order/${orderId}/create-receipt/`, {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating receipt:', error);
    throw error;
  }
};
