import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AuthLayout from './components/Authentication/AuthLayout';
import RegistrationForm from './components/Authentication/RegistrationForm';
import LoginForm from './components/Authentication/LoginForm';
import SuccessPage from './components/Authentication/SuccessPage';
import PasswordReset from './components/Authentication/PasswordReset';
import PasswordResetForm from './components/Authentication/PasswordResetForm';
import PasswordEmailSent from './components/Authentication/PasswordEmailSent';
import SuccessVerificationPage from './components/Authentication/SuccessVerificationPage';
import Dashboard from './components/Dashboard/Dashboard';
import POS from './components/POS/POS'
import OnlineMenu from './components/Users/OnlineMenu';



function App() {
  return (
    <div className="App">
      <main>
        <Routes>
          <Route path="/register" element={<AuthLayout><RegistrationForm /></AuthLayout>} />
          <Route path="/login" element={<AuthLayout><LoginForm /></AuthLayout>} />
          <Route path="/success" element={<AuthLayout><SuccessPage /></AuthLayout>} />
          <Route path="/password-reset" element={<AuthLayout><PasswordReset /></AuthLayout>} />
          <Route path="/password-email-sent" element={<AuthLayout><PasswordEmailSent /></AuthLayout>} />
          <Route path="/password-confirm/:uidb64/:token" element={<AuthLayout><PasswordResetForm /></AuthLayout>} />
          <Route path="/auth/verify_email/:uuid" element={<AuthLayout><SuccessVerificationPage /></AuthLayout>} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/pos/*" element={<POS />} />
          <Route path="/users/live-menu/restaurant/:restaurantId/table/:tableId/:menuId" element={<OnlineMenu />} />
          {/* You can use a dynamic segment in the URL to pass the restaurant ID */}
        </Routes>
      </main>
    </div>
  );
}

export default App;
