import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo_transparent.png'; 
import { useAuth } from '../../contexts/AuthContext';
import { fetchUserProfile, staffLogout } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const Logo = styled.img`
  width: 25px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #1f1f1f;
  color: #fff;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const StaffInfo = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  font-size: 17px;
  padding-left: 20px;
  @media (max-width: 600px) {
    width: 50%;
    padding-left: 10px;
  }
`;

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  flex: 1;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  padding-left: 10px;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  padding-right: 10px;
`;

const LogoutContainer = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;
  font-size: 17px;
  padding-right: 20px;
  color: #ff6a3ee3;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 50%;
    padding-right: 10px;
  }
`;

const POSHeader = () => {
  const { token, logout } = useAuth();
  const [staffInfo, setStaffInfo] = useState({ first_name: '', last_name: '' });
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const info = await fetchUserProfile(token);
        setStaffInfo(info);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    if (token) {
      fetchInfo();
    }
  }, [token]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTimeWithoutSeconds = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleLogout = async () => {
    try {
      await staffLogout();
      logout();
      navigate('/pos/clock-in'); // Redirect to login or other appropriate page
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <HeaderContainer>
      <Logo style={{ marginLeft: '5px' }} src={logo} alt="Logo" />
      <StaffInfo>
        <div>{staffInfo.first_name} {staffInfo.last_name}</div>
      </StaffInfo>
      {!isMobile && (
        <DateTimeContainer>
          <TimeContainer>{currentTime.toLocaleDateString()}</TimeContainer>
          <DateContainer>{formatTimeWithoutSeconds(currentTime)}</DateContainer>
        </DateTimeContainer>
      )}
      <LogoutContainer onClick={handleLogout}>Clock-out</LogoutContainer>
    </HeaderContainer>
  );
};

export default POSHeader;
