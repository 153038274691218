import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { updateRestaurant } from '../../../services/apiService';
import { TextField, Button, Typography, Box } from '@mui/material';

const RestaurantForm = ({ context, onClose }) => {
  const [restaurantName, setRestaurantName] = useState('');
  const [legalAddress, setLegalAddress] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const { editingRestaurant, updateEditedRestaurant, loadUserRestaurants, showSidebar } = useRestaurant();

  const resetForm = () => {
    setRestaurantName('');
    setLegalAddress('');
    setVatNumber('');
  };

  useEffect(() => {
    if (context === 'editRestaurant' && editingRestaurant) {
      setRestaurantName(editingRestaurant.name);
      setLegalAddress(editingRestaurant.legal_address || '');
      setVatNumber(editingRestaurant.vat_number || '');
    } else {
      resetForm();
    }
  }, [context, editingRestaurant, showSidebar]);

  useEffect(() => {
    if (!showSidebar) {
      resetForm();
    }
  }, [showSidebar]);

  const handleUpdateRestaurant = async (event) => {
    event.preventDefault();
    if (!restaurantName.trim() || !legalAddress.trim() || !vatNumber.trim()) {
      console.error("Restaurant name, legal address, and VAT number cannot be empty");
      return;
    }

    const token = localStorage.getItem('access_token');
    const restaurantData = {
      name: restaurantName,
      legal_address: legalAddress,
      vat_number: vatNumber
    };
    const response = await updateRestaurant(editingRestaurant.id, restaurantData, token);
    updateEditedRestaurant(response);

    resetForm();
    onClose();
    loadUserRestaurants();
  };

  return (
    <Box component="form" onSubmit={handleUpdateRestaurant}>
      <Typography variant="h6">Update Restaurant</Typography>
      <Typography variant="body2">The Restaurant Name will not be visible to your customers</Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Restaurant Name"
        value={restaurantName}
        onChange={(e) => setRestaurantName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Legal Address"
        placeholder="Full legal address of the restaurant"
        value={legalAddress}
        onChange={(e) => setLegalAddress(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="VAT Number"
        value={vatNumber}
        onChange={(e) => setVatNumber(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit">
        Update Restaurant
      </Button>
    </Box>
  );
};

export default RestaurantForm;
