import React, { useState, useEffect, useCallback } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import styled from 'styled-components';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { fetchTablesForArea, updateTable, deleteTable, updateTablePositions, fetchAllTablesForRestaurant } from '../../../services/apiService';
import { DeleteConfirmationDialog } from '../../Common/MUI.StyledComponents';
import { debounce } from 'lodash';
import { Slider, TextField, Snackbar, Alert } from '@mui/material';

const Container = styled.div`
  padding: 10px;
  padding-top: 20px;
  background-color: var(--color-lighter);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const TableContainer = styled.div`
  cursor: grab;
  width: 100%;
  height: 100%;
  flex: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  position: relative;
  user-select: none;
  &:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
`;

const TableNumber = styled.h4.withConfig({
  shouldForwardProp: (prop) => prop !== 'isEditing',
})`
  align-self: flex-start;
  padding-top: 10px;
  padding-left: 10px;
  text-align: left;
  margin: 0;
  color: var(--color-dark);
  cursor: pointer;
  transition: background-color 0.3s, border-radius 0.3s;
  background-color: ${({ isEditing }) => (isEditing ? 'rgba(255, 255, 255, 0.2)' : 'transparent')};
  border-radius: ${({ isEditing }) => (isEditing ? '4px' : '0')};
`;

const TableSeat = styled.p`
  align-self: flex-start;
  padding-left: 10px;
  text-align: left;
  font-size: 15px;
  margin: 0;
  color: var(--color-dark);
`;

const DeleteIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const EditInputWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root {
    background-color: var(--color-secondary);
    border-radius: 5px;
    font-size: 1.4117647058824rem; // h4 value in app.css
    font-weight: 600;
    margin-left: 15px; // Make room for the hash symbol
    margin-top: 1px;
    height: 25px;
    width: 40px;
    outline: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiInputBase-input {
    color: white;
    padding: 0;
    margin: 0;
  }
`;

const HashSymbol = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-dark);
  font-size: 1.4117647058824rem; // h4 value in app.css
  font-weight: 600;
`;

// Grid and seats definitions
const ResponsiveGridLayout = WidthProvider(Responsive);

const FloorMapComponent = ({ areaId }) => {
  const { selectedRestaurant, refreshTables } = useRestaurant();
  const [tables, setTables] = useState([]);
  const [layout, setLayout] = useState([]);
  const [originalLayout, setOriginalLayout] = useState([]);
  const [sortedLayout, setSortedLayout] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);
  const [editingTableId, setEditingTableId] = useState(null);
  const [editingTableNumber, setEditingTableNumber] = useState('');
  const [initialTableNumber, setInitialTableNumber] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const formatLayout = useCallback((tables) => {
    return tables.map((table) => ({
      i: table.id.toString(),
      x: table.position_x,
      y: table.position_y,
      w: table.width,
      h: table.height,
    }));
  }, []);

  const loadTables = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    const fetchedTables = await fetchTablesForArea(areaId, token);
    setTables(fetchedTables);
    const newLayout = formatLayout(fetchedTables);
    setLayout(newLayout);
    setOriginalLayout(newLayout);

    // Create sorted layout by table number
    const sortedTables = [...fetchedTables].sort((a, b) => a.number - b.number);
    const newSortedLayout = sortedTables.map((table, index) => ({
      i: table.id.toString(),
      x: 0,
      y: index,
      w: table.width,
      h: table.height,
    }));
    setSortedLayout(newSortedLayout);
  }, [areaId, formatLayout]); // Added formatLayout as dependency

  useEffect(() => {
    loadTables(); // Initial load
  }, [areaId, loadTables]);

  useEffect(() => {
    loadTables(); // Re-fetch tables when refreshTables changes
  }, [refreshTables, loadTables]);

  const updateLayoutPositions = debounce(async (updates, token) => {
    try {
      await updateTablePositions(updates, token);
      console.log('Layout updated successfully!');
    } catch (error) {
      console.error('Failed to update layout:', error);
    }
  }, 200);

  const handleLayoutChange = (newLayout) => {
    if (!isDragging) return;

    const updates = newLayout.map((layoutItem) => {
      const table = tables.find((t) => t.id.toString() === layoutItem.i);
      if (table) {
        return {
          id: table.id,
          position_x: layoutItem.x,
          position_y: layoutItem.y,
          width: layoutItem.w,
          height: layoutItem.h,
        };
      }
      return null;
    }).filter((t) => t !== null);

    if (updates.length > 0) {
      const token = localStorage.getItem('access_token');
      updateLayoutPositions(updates, token);
    }
  };

  const handleStart = () => setIsDragging(true);
  const handleStop = () => {
    setIsDragging(false);
    setLayout(formatLayout(tables)); // To ensure layout is updated after drag
  };

  const handleDeleteIconClick = (tableId) => {
    setOpenDeleteDialog(true);
    setTableToDelete(tableId);
  };

  const handleConfirmDelete = async () => {
    const token = localStorage.getItem('access_token');
    try {
      await deleteTable(tableToDelete, token);
      setTables(tables.filter((table) => table.id !== tableToDelete));
      setOpenDeleteDialog(false);
      setTableToDelete(null);
    } catch (error) {
      console.error('Error deleting table:', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setTableToDelete(null);
  };

  const handleEditTableNumberClick = (tableId, tableNumber) => {
    setEditingTableId(tableId);
    setEditingTableNumber(tableNumber);
    setInitialTableNumber(tableNumber);
  };

  const handleEditTableNumberChange = (event) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 3) {
      setEditingTableNumber(newValue);
    }
  };

  const handleEditTableNumberSubmit = async () => {
    if (!editingTableId || editingTableNumber === initialTableNumber) {
      setEditingTableId(null);
      setEditingTableNumber('');
      return;
    }

    const token = localStorage.getItem('access_token');
    const allTables = await fetchAllTablesForRestaurant(selectedRestaurant, token);
    const existingTable = allTables.find(
      (table) => table.number === parseInt(editingTableNumber)
    );

    if (existingTable && existingTable.id !== editingTableId) {
      setSnackbarMessage('Table number already exists.');
      setSnackbarOpen(true);
      return;
    }

    try {
      await updateTable(editingTableId, { number: editingTableNumber, area: areaId }, token);
      setTables((prevTables) =>
        prevTables.map((table) =>
          table.id === editingTableId ? { ...table, number: editingTableNumber } : table
        )
      );
      setEditingTableId(null);
      setEditingTableNumber('');
    } catch (error) {
      console.error('Error updating table number:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleEditTableNumberSubmit();
    } else if (event.key === 'Escape') {
      setEditingTableId(null);
      setEditingTableNumber('');
    }
  };

  const handleBlur = () => {
    handleEditTableNumberSubmit();
  };

  const handleBreakpointChange = (newBreakpoint) => {
    if (newBreakpoint === 'xs' || newBreakpoint === 'xxs') {
      setLayout(sortedLayout);
    } else {
      setLayout(originalLayout);
    }
  };

  const handleSeatChange = async (tableId, newSeats) => {
    const updatedTables = tables.map(table => {
      if (table.id === tableId) {
        return { ...table, number_of_seats: newSeats };
      }
      return table;
    });
    setTables(updatedTables);

    const token = localStorage.getItem('access_token');
    const tableToUpdate = updatedTables.find(table => table.id === tableId);
    await updateTable(tableId, { area: areaId, number_of_seats: tableToUpdate.number_of_seats }, token);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: originalLayout, md: originalLayout, sm: originalLayout, xs: sortedLayout, xxs: sortedLayout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 8, md: 8, sm: 8, xs: 1, xxs: 1 }}
        rowHeight={100}
        onLayoutChange={handleLayoutChange}
        onDragStart={handleStart}
        onDragStop={handleStop}
        onBreakpointChange={handleBreakpointChange}
        isResizable={false}
        isDraggable={true}
        compactType={null}
        draggableCancel=".MuiSlider-root"
        autoSize={true}
        margin={[20, 20]}
        containerPadding={[20, 20]}
      >
        {tables.map((table) => (
          <div key={table.id} data-grid={layout.find((l) => l.i === table.id.toString())}>
            <TableContainer>
              <DeleteIcon onClick={() => handleDeleteIconClick(table.id)} size="small">
                <DisabledByDefaultRoundedIcon fontSize="small" sx={{ cursor: 'pointer' }} color="secondary" />
              </DeleteIcon>
              {editingTableId === table.id ? (
              <TableNumber>
                <EditInputWrapper>
                  <HashSymbol>#</HashSymbol>
                  <StyledTextField
                    value={editingTableNumber}
                    onChange={handleEditTableNumberChange}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    autoFocus
                    inputProps={{ maxLength: 3 }}
                  />
                </EditInputWrapper>
              </TableNumber>
              ) : (
                <TableNumber isEditing={editingTableId === table.id} onClick={() => handleEditTableNumberClick(table.id, table.number)}>#{table.number}</TableNumber>
              )}
              <TableSeat>{table.number_of_seats} seats</TableSeat>
              <Slider
                value={table.number_of_seats}
                min={1}
                max={12}
                step={1}
                onChange={(event, newValue) => handleSeatChange(table.id, newValue)}
                aria-labelledby="continuous-slider"
                sx={{ width: '80%', margin: '10px', color: 'var(--color-primary)' }}
                size="small"
              />
            </TableContainer>
          </div>
        ))}
      </ResponsiveGridLayout>
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        instanceName="table"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FloorMapComponent;
